import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';

@Pipe({
    name: 'mechanicType'
})
export class MechanicTypePipe implements PipeTransform {

    transform(planningHass: PlanningHasEntity, mechanicId: number, trigger?: any): 'main' | 'secondary' {
        if (planningHass.mechanics.find(m => m.mechanic_id === mechanicId)) {
            return 'main';
        }
        if (planningHass.mechanics.find(m => m.sec_mechanic_id === mechanicId)) {
            return 'secondary';
        }
        return null;
    }

}
