import {Injectable} from '@angular/core';
import {ApiResponse, ApiService} from '../api/api.service';
import {Entity} from '../../classes/entity.class';
import {EntityType} from './entity-type.class';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {combineLatest, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class EntitiesService extends RealtimeService<Entity> {

    private _types$: Observable<ApiResponse<EntityType[]>>;

    constructor(protected apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.entities);
    }

    public itemInDaterange(entity: Entity, fromDate: Date, toDate: Date) {
        const fromTime = fromDate.getTime();
        const toTime = toDate.getTime();
        return new Date(entity.begindate).getTime() < toTime
            && (new Date(entity.enddate).getTime() >= fromTime || entity.enddate == null);
    }

    public get(id: number): Promise<Entity> {
        return this.apiService.getCall(`${this.type}/${id}`);
    }

    public getByType(code): Observable<Entity[]> {
        return new Observable((observer) => {
            this.getList().subscribe((entities) => {
                observer.next(entities.filter(entity => {
                    if (Array.isArray(code)) {
                        return entity.entitytypes.map(et => et.id).filter(etId => code.includes(etId))?.length > 0;
                    } else {
                        return entity.entitytypes.map(et => et.id).includes(code);
                    }
                }));
            });
        });
    }

    public getList(beginDate?: Date, endDate?: Date): Observable<Entity[]> {
        return combineLatest([super.getList(), this.getTypes()]).pipe(map(([list, types]) => {
            if (beginDate) {
                if (!endDate) {
                    endDate = beginDate;
                }
                return list.filter(entity => {
                    return new Date(entity.begindate).getTime() < new Date(beginDate).getTime() &&
                        (!entity.enddate || new Date(entity.enddate).getTime() > new Date(endDate).getTime());
                });
            }
            list = list.sort((a, b) => {
                const getSorter = (entity: Entity) => Math.max(...entity.entitytypes.map(et => +(et.pivot?.order ?? 99999)));
                return getSorter(a) - getSorter(b);
            });
            return list.filter(l => l.entitytypes?.length > 0);
        })).pipe(map(a => a.sort((a, b) => {
            const getSorter = (entity: Entity) => (entity.use_once ? 0 : 1000);
            return getSorter(a) - getSorter(b);
        })));
    }

    public getMap(): Observable<Map<number, Entity>> {
        return new Observable((observer) => {
            this.getList().subscribe((entities: Entity[]) => {
                observer.next(this.genEntitiesMap(entities));
            });
        });
    }

    public getUnavailableEntityIds(planningId: number, beginDate: Date, endDate: Date): Promise<number[]> {
        return this.apiService.postCall(`${this.type}/not-available`,
            {planningId, beginDate, endDate}
        );
    }

    public save(entity: Entity): Promise<Entity> {
        return this.apiService.postCall(this.type, entity);
    }

    public changeOrder(entities: { order, entity_id, entitytype_id }[], entitytypeId: number): Promise<boolean> {
        return this.apiService.postCall(`${this.type}/change-order`, {
            entities,
            entitytypeId
        });
    }

    public delete(id: number): Promise<Entity> {
        return this.apiService.deleteCall(`${this.type}/${id}`);
    }

    public getTypes() {
        return this._types$ ??= this.apiService.getCall$<EntityType[]>('entities/types').pipe(shareReplay());
    }

    public getTypesMap(): Observable<Map<number, EntityType>> {
        return this.getTypes().pipe(map(types => {
            const typeMap = new Map<number, EntityType>();
            types.data.forEach(type => {
                typeMap.set(type.id, type);
            });
            return typeMap;
        }));
    }

    private genEntitiesMap(entities: Entity[]): Map<number, Entity> {
        const map = new Map<number, Entity>();
        entities.forEach(entity => {
            map.set(entity.id, entity);
        });
        return map;
    }
}

