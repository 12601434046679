import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';

@Pipe({
    name: 'mechanicSecondary'
})
export class MechanicSecondaryPipe implements PipeTransform {

    transform(planningHass: PlanningHasEntity, mechanicId: number, trigger?: any): number {
        return planningHass.mechanics.find(m => m.sec_mechanic_id === mechanicId)?.mechanic_id;
    }

}
