import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../../codalt.component';

import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {VkmVehicle} from '../../../classes/vkm-vehicle';
import {VkmService} from '../../../services/vkm.service';
import {User} from '../../../classes/user.class';

@Component({
    selector: 'app-edit-vkm-vehicle-dialog',
    templateUrl: './edit-vkm-vehicle-dialog.component.html',
    styleUrls: ['./edit-vkm-vehicle-dialog.component.scss']
})
export class EditVkmVehicleDialogComponent extends CodaltComponent implements OnInit {

    saving = false;

    form: FormGroup<{
        id: FormControl<number>,
        user_id: FormControl<number>,
        date: FormControl<Date>,
        vehicle: FormControl<string>,
        common: FormControl<boolean>
    }>;

    constructor(public dialogRef: MatDialogRef<EditVkmVehicleDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    vehicle: VkmVehicle,
                    currentDate: Date,
                    user: User
                },
                private vkmService: VkmService) {
        super();
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl(this.data?.vehicle?.id),
            user_id: new FormControl(this.data?.vehicle?.user_id),
            date: new FormControl(this.data?.vehicle?.date),
            vehicle: new FormControl(this.data?.vehicle?.vehicle),
            common: new FormControl(!this.data.vehicle?.date)
        });
        this.subscriptions.add(this.form.controls.common.valueChanges.subscribe(common => {
            if (common) {
                this.form.controls.date.setValue(null);
            } else {
                this.form.controls.date.setValue(this.data.currentDate);
            }
        }));
    }

    save() {
        this.saving = true;
        this.subscriptions.add(this.vkmService.saveVehicle(this.form.value).subscribe(vehicle => {
            this.saving = false;
            this.dialogRef.close();
        }, () => {
            this.saving = false;
        }));
    }

}
