import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {EntitiesService} from '../../services/entities/entities.service';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {Entity} from '../../classes/entity.class';
import {PlanningHasService} from '../../services/planning/planning-has.service';
import {Utils} from '../../utils.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Subscription} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {VkmService} from '../../services/vkm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {User} from '../../classes/user.class';
import {UserService, UserType} from '../../services/user/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MechanicTypePipe} from './mechanic-type.pipe';
import {EditVkmVehicleDialogComponent} from './edit-vkm-vehicle-dialog/edit-vkm-vehicle-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {VkmVehicleService} from '../../services/vkm-vehicle.service';
import {VkmVehicle} from '../../classes/vkm-vehicle';

@Component({
    selector: 'app-vkm-day-planning',
    templateUrl: './vkm-day-planning.component.html',
    styleUrls: ['./vkm-day-planning.component.scss']
})
export class VkmDayPlanningComponent extends CodaltComponent implements OnInit {

    currentDate = new Date();

    usersMap: Map<number, User>;
    mechanics: User[];
    teams: Entity[];
    teamLists = [];
    planningHass: PlanningHasEntity[];
    userVehicleMap: Map<number, VkmVehicle>;

    trigger = 0;

    planningSubscription = new Subscription();

    constructor(private entitiesService: EntitiesService,
                private vkmService: VkmService,
                private vkmVehicleService: VkmVehicleService,
                private planningHasService: PlanningHasService,
                private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.userService.getMap().subscribe(map => {
            this.usersMap = map;
        }));
        this.subscriptions.add(this.userService.getByType(UserType.VKMMECHANIC).subscribe(users => {
            this.mechanics = [{id: null, name: 'Geen ploeg'} as any, ...users];
            this.teamLists = this.mechanics.map(t => `mechanic-${t.id}`);
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date }) => {
            let date = params.date;
            if (!date || date.length === 0) {
                date = new Date();
            }
            this.currentDate = new Date(date);
            Utils.setTime(this.currentDate, 0, 0);
            this.getData();
        }));
    }

    reload() {
        this.planningHasService.disconnect();
        this.planningHass = [];
        this.getData();
    }

    private getData() {
        this.planningSubscription.unsubscribe();
        this.planningSubscription = new Subscription();
        const beginDate = new Date(this.currentDate);
        Utils.setTime(beginDate, 0, 0);
        const endDate = new Date(this.currentDate);
        Utils.setTime(endDate, 23, 59);
        const planningHass$ = this.planningHasService.getFilteredList(beginDate, endDate, EntityTypeCode.VKMWerkopdracht);
        this.planningSubscription.add(planningHass$.subscribe(planningHas => {
            this.planningHass = planningHas.filter(ph => ph.entitytype_id === EntityTypeCode.VKMWerkopdracht);
        }));
        this.planningSubscription.add(this.vkmVehicleService.getFilteredList(beginDate, endDate).subscribe(vehicle => {
            this.userVehicleMap = new Map<number, VkmVehicle>();
            vehicle.forEach(vehicle => {
                if (!this.userVehicleMap.get(vehicle.user_id)?.date) {
                    this.userVehicleMap.set(vehicle.user_id, vehicle);
                }
            });
        }));

        let wasOpen = false;
        let websocketBar = null;
        let websocketBarSubs = null;
        this.planningSubscription.add(this.planningHasService.websocketOpen.subscribe(isOpen => {
            if (wasOpen) {
                if (!isOpen) {
                    if (!websocketBar) {
                        websocketBar = this.snackBar.open('Verbinding met planning verbroken', 'Opnieuw verbinden');
                        websocketBarSubs = websocketBar.afterDismissed().subscribe(() => {
                            document.location.reload();
                            websocketBarSubs?.unsubscribe();
                            websocketBar = null;
                        });
                    }
                } else {
                    websocketBarSubs?.unsubscribe();
                    websocketBar?.dismiss();
                    websocketBar = null;
                }
            }
            wasOpen = true;
        }));
    }

    today() {
        this.currentDate = new Date();
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    next() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1));
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    drop(event: CdkDragDrop<string[]>) {
        const newUser = event.container.data as any as User;
        const oldUser = event.previousContainer.data as any as User;
        const planningHas = event.item.data as PlanningHasEntity;
        const type = (new MechanicTypePipe()).transform(planningHas, oldUser.id);
        const field = type === 'secondary' ? 'sec_mechanic_id' : 'mechanic_id';
        const vkmMechanic = planningHas.mechanics.find(m => m[field] === oldUser?.id);
        if (vkmMechanic) {
            vkmMechanic[field] = newUser?.id;
            this.trigger++;
        }

        this.planningSubscription.add(this.vkmService.entityUpdate(planningHas.id, oldUser?.id, newUser?.id, type === 'secondary').subscribe(vkmMechanics => {
            planningHas.mechanics = vkmMechanics.data;
            this.trigger++;
        }));
    }

    add(mechanic: User) {
        const duration = 4;
        const hour = 7;
        const fromDate = new Date(this.currentDate);
        const toDate = new Date(this.currentDate);
        fromDate.setHours(hour);
        toDate.setHours(hour);
        toDate.setMinutes(toDate.getMinutes() + (60 * duration));
        this.router.navigate([`vkm/new`, {fromDate: fromDate.toISOString(), toDate: toDate.toISOString(), mechanicId: mechanic.id, backTo: 'vkm-planning'}]);
    }

    open(planningHas: PlanningHasEntity) {
        this.router.navigate([
            `vkm/${planningHas.planning_id}`,
            {
                backTo: `vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`,
                planningHas: planningHas.id
            }
        ]);
    }

    editVehicle(vehicle: VkmVehicle, userId) {
        if (!vehicle) {
            vehicle = new VkmVehicle();
            vehicle.date = null;
            vehicle.user_id = userId;
        }
        this.dialog.open(EditVkmVehicleDialogComponent, {
            maxWidth: '300px',
            maxHeight: '300px',
            disableClose: false,
            panelClass: 'vehicle-edit-dialog',
            data: {
                vehicle,
                currentDate: this.currentDate,
                user: this.usersMap.get(userId)
            }
        });
    }
}
