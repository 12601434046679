<div *ngFor="let mill of mills">

    <table class="table table-bordered table-striped text-center">
        <thead>
        <tr class="title">
            <th colspan="10">
                <h1>Bestellijst asfalt week {{monday | date: 'w y'}}</h1>
                <h2>{{mill.name}}</h2>
            </th>
        </tr>
        <tr>
            <th>Dag</th>
            <th>D/N</th>
            <th>Werknummer</th>
            <th>Soort</th>
            <th>Code</th>
            <th>Tijd</th>
            <th>Ton</th>
            <th>Uitvoerder</th>
            <th>Projectleider</th>
            <th>Plaatsnaam werk</th>
        </tr>
        </thead>

        <ng-container *ngFor="let asf of asphaltList">
            <ng-container *ngIf="asf.asphaltmill_id === mill.id">
                <tr>
                    <td>{{asf.time | date : 'E d-MM'}}</td>
                    <td>{{Utils.isNight(asf.time, asf.time) ? 'N' : 'D'}}</td>
                    <td>{{asf.planning.worknumber ?? asf.planning.afas_project_id}}</td>
                    <td>{{asf.asphaltname}}</td>
                    <td>{{asf.asphaltcode}}</td>
                    <td>{{asf.time | date : 'HH:mm'}}</td>
                    <td>{{asf.tons}}</td>
                    <td>{{users?.get(asf.planning.asphalt_performer)?.name}}</td>
                    <td>{{users?.get(asf.planning.projectmanager)?.name}}</td>
                    <td>{{asf.planning?.asphalt_location ?? asf.planning.location}}</td>
                </tr>
            </ng-container>
        </ng-container>
    </table>
    <br>
    <i>Lijst gegenereerd op {{now | date:'EEEE dd MMMM y'}} om {{now | date:'H:mm'}} uur door {{authenticatedUser?.name}}.</i>
</div>
