import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Planning} from '../classes/planning.class';
import {VkmQuestion} from '../classes/vkmQuestion';
import {VkmAnswer} from '../classes/vkm-answer';
import {map} from 'rxjs/operators';
import {VkmMechanic} from '../classes/vkm-mechanic';
import {VkmVehicle} from '../classes/vkm-vehicle';
import {formatDate} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class VkmService {

    constructor(private apiService: ApiService) {
    }

    getPdf(planningId, planningHasId) {
        return this.apiService.getBlobCall(`vkm/pdf/${planningId}/${planningHasId}`);
    }

    saveAnswers(answers) {
        return this.apiService.postCall$('vkm/checklist/answers', answers);
    }

    getAnswers(planning_has_id: number) {
        return this.apiService.getCall$<VkmAnswer[]>('vkm/checklist/answers', {planning_has_id});
    }

    getAllAnswers(planning_has_id: number) {
        return this.apiService.getCall$<{
            answers: VkmAnswer[],
            user_id: number
        }[]>('vkm/checklist/answers/all', {planning_has_id});
    }

    getQuestions() {
        return this.apiService.getCall$<{
            controlepunten: VkmQuestion[],
            werkoverdracht: VkmQuestion[]
        }>('vkm/checklist/questions');
    }


    getWorkorderList(search?: string, history?: boolean) {
        return this.apiService.getCall$<Workorder[]>('vkm/workorders', {search, history});
    }

    getProjectList() {
        return this.apiService.getCall$<Planning[]>('vkm/projects');
    }

    countsAndPrices(planningId) {
        return this.apiService.getCall$<{
            objects: VkmObjectCalculation[],
            materials: VkmMaterialCalculation[],
            totalPrices: SettleGroupPrice[],
            totalPrice: number
        }>(`vkm/counts-and-prices/${planningId}`).pipe(map(data => {
            data.data.materials.forEach(o => {
                o.count_placed = o.count_placed ? +o.count_placed : 0;
            });
            data.data.objects.forEach(o => {
                o.count_lost = o.count_lost ? +o.count_lost : 0;
                o.count_placed = o.count_placed ? +o.count_placed : 0;
                o.count_removed = o.count_removed ? +o.count_removed : 0;
            });
            return data;
        }));
    }

    save(planning: Planning) {
        return this.apiService.postCall$<Planning>('vkm', planning);
    }

    idByProject(afasProjectId: string) {
        return this.apiService.getCall$<number>(`vkm/id-by-project/${afasProjectId}`);
    }

    entityUpdate(id, oldMechanicId, newMechanicId, secondary?: boolean) {
        return this.apiService.postCall$<VkmMechanic[]>('vkm/entity-update', {
            id,
            oldMechanicId,
            newMechanicId,
            secondary
        });
    }

    saveVehicle(vkmVehicle: VkmVehicle) {
        if (vkmVehicle.date) {
            vkmVehicle.date = formatDate(vkmVehicle.date, 'yyyy-MM-dd', 'nl') as any;
        }
        return this.apiService.postCall$<VkmVehicle>('vkm/save-vehicle', vkmVehicle);
    }
}

export class Workorder {
    id: number;
    enddate: Date;
    begindate: Date;
    type: string;
    vg_plan: string;
    comment: string;
    planning_id: number;
    afas_project_id: string;
    description: string;
    contractor: string;
    location: string;
}

export class VkmObjectCalculation {
    count_lost: number;
    count_placed: number;
    count_removed: number;
    date_placed: Date;
    date_removed: Date;
    entity_id: number;
    price: number;
    price_total: number;
    price_unit: number;
    price_unit_total: number;
    total_days: number;
    days: number;
}

export class VkmMaterialCalculation {
    count_placed: number;
    entity_id: number;
    price: number;
    price_total: number;
    price_unit: number;
    price_unit_total: number;
}

export class SettleGroupPrice {
    settle_group: string;
    price: number;
}
