import {Component, EventEmitter, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {PlanningService} from '../services/planning/planning.service';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {combineLatest, Subscription} from 'rxjs';
import {EntitiesService} from '../services/entities/entities.service';
import {UserService, UserType} from '../services/user/user.service';
import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {PlanningTruck} from '../classes/planningtruck.class';
import {PlanningCutter} from '../classes/planningcutter.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {PlanningAsfaltteam} from '../classes/planningasfaltteam.class';
import {Entity} from '../classes/entity.class';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {DefaultStand, User} from '../classes/user.class';
import {FormControl, FormGroup} from '@angular/forms';
import {TimeOption} from '../classes/time-option';
import {Settings} from '../settings.class';
import {DayTimeOptions} from '../classes/day-time-options';
import {AsphaltService} from '../services/asphalt/asphalt.service';
import {Asphaltmill} from '../classes/asphaltmill.class';
import {PlanningSet} from '../classes/planningset.class';
import {PlanningDumper} from '../classes/planningdumper.class';
import {EmployeesDialogComponent} from './employees-dialog/employees-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {debounceTime, startWith, tap} from 'rxjs/operators';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {HiringService} from '../services/hiring.service';
import {Hiring} from '../classes/hiring';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {formatDate} from '@angular/common';
import {LocalStorage} from '../storage.class';
import {Title} from '@angular/platform-browser';
import {EntityWithAvailable} from '../classes/entity-with-available.class';
import {EntityUnavailableService} from '../services/entities/entity-unavailable.service';
import {EntityUnavailable} from '../classes/entityunavailable.class';
import {EquipmentDialogComponent} from './equipment-dialog/equipment-dialog.component';
import {PlanningFixedService} from '../services/planning/planning-fixed.service';
import {PlanningStatus} from '../planning-status.enum';
import {PlanningDetailDialogService} from '../services/dialog/planning-detail-dialog.service';
import {localUserAvailableValidator} from '../validators/local-user-available.validator';
import {localEntityAvailableValidator} from '../validators/local-entity-available.validator';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatMenuTrigger} from '@angular/material/menu';
import {Address, LocationService} from '../services/location.service';
import {AfasProject} from '../afas-classes/afas-project';
import {PlanningHasService} from '../services/planning/planning-has.service';
import {UserPlanningService} from '../services/planning/user-planning.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Leave} from '../classes/leave';
import {TransportPlanningStatusService} from '../services/planning/transport-planning-status.service';

interface PlanningForm {
    mainPlanning: PlanningHasEntity;
    planning?: Planning;
    entities: {
        fg: FormGroup,
        planning: PlanningHasEntity,
        highlight?: boolean,
        selected?: boolean
    }[];
    hasSets?: boolean;
    asphaltTrucks?: number;
    cutterTrucks?: number;
    saving?: boolean;
}

@Component({
    selector: 'app-transport-planning',
    templateUrl: './transport-planning.component.html',
    styleUrls: ['./transport-planning.component.scss']
})
export class TransportPlanningComponent implements OnInit, OnDestroy {

    readOnly = true;
    readOnlyAllStatus = false;

    planning: Planning[];
    curDayPlannings: Planning[];
    dayIsFixed = true;
    weeks: WeekOption[] = [];
    today = new Date();
    currentDate = new Date();
    environment = environment;
    entitiesMap: Map<number, Entity>;
    EntityTypeMap: Map<number, EntityType>;
    startTimesEntity: Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]> = new Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]>();
    endTimesEntity: Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]> = new Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]>();
    teams = [EntityTypeCode.AsfaltTeam];
    projectManagers: User[];
    executors: User[];
    drivers: User[];
    trucks: Entity[];
    lowLoaders: Entity[];
    allEntities: Entity[];
    unavailableList: EntityUnavailable[];
    EntityTypeCode = EntityTypeCode;
    DefaultStand = DefaultStand;
    entityTypes: EntityType[];
    hirings: Hiring[];
    hiringMap = new Map<number, Hiring[]>();
    planningForms: PlanningForm[] = [];
    mills: Asphaltmill[];
    downloadingPdf = false;
    PlanningStatus = PlanningStatus;
    initialValuesSaved = false;
    mobile = false;
    addresses: string[];
    employees: User[];
    now = new Date();
    planningHass: PlanningHasEntity[];
    saveSorting = new EventEmitter<any>();

    fcShowSmallMaterials = new FormControl(LocalStorage.ShowSmallMaterials ?? false);

    fcShowInUse = new FormControl(LocalStorage.ShowInUse ?? false);

    fcShowComments = new FormControl(LocalStorage.ShowComments ?? false);

    fcPerformerIds = new FormControl();

    fcPlanningStatus = new FormControl();

    blockedMap: Map<number, { icon: string; description: string; }>;
    leaveDayUserMap: Map<number, Leave> = new Map<number, Leave>();

    hiringAutocomplete = new Map<number, string[]>();

    @HostBinding('class.print') print = false;
    private subscriptions = new Subscription();
    private formDaySubscriptions = new Subscription();

    constructor(private entitiesService: EntitiesService,
                private usersService: UserService,
                private dialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private planningDetailDialogService: PlanningDetailDialogService,
                private route: ActivatedRoute,
                private router: Router,
                private asphaltService: AsphaltService,
                private hiringService: HiringService,
                private planningService: PlanningService,
                private planningFixedService: PlanningFixedService,
                private title: Title,
                private locationService: LocationService,
                private breakpointObserver: BreakpointObserver,
                private entityUnavailableService: EntityUnavailableService,
                private planningHasService: PlanningHasService,
                private userPlanningService: UserPlanningService,
                private transportPlanningStatusService: TransportPlanningStatusService,
                private userService: UserService) {
        const params = this.route.snapshot.queryParams as { print };
        this.print = !!params?.print;
        if (this.print) {
            this.subscriptions.add(this.entitiesService.getTypes().subscribe(types => {
                LocalStorage.PlanningFilter = types.data.filter(t => t.is_team).map(t => t.id);
            }));
        }
        this.subscriptions.add(this.fcPlanningStatus.valueChanges.subscribe(value => {
            this.subscriptions.add(this.transportPlanningStatusService.setPlanningStatus(formatDate(this.currentDate, 'yyyy-MM-dd', 'nl'), value).subscribe());
        }));
        this.subscriptions.add(this.fcShowInUse.valueChanges.subscribe(value => {
            LocalStorage.ShowInUse = value;
        }));
        this.subscriptions.add(this.fcShowSmallMaterials.valueChanges.subscribe(value => {
            LocalStorage.ShowSmallMaterials = value;
        }));
        this.subscriptions.add(this.fcShowComments.valueChanges.subscribe(value => {
            LocalStorage.ShowComments = value;
        }));
        this.subscriptions.add(this.saveSorting.pipe(debounceTime(1000)).subscribe(() => {
            const planningHass = this.planningForms.map(pf => pf.entities.map(e => e.planning))
                .reduce((a, v) => a.concat(v), [])
                .filter(p => !!p.orderChanged);
            this.subscriptions.add(this.planningService.saveSort(planningHass).subscribe());
        }));

        this.title.setTitle('Transportplanning' + environment.titleAppend);
    }

    ngOnInit() {
        this.readOnly = !UserService.userHasRights(UserType.WORKPLANNER);
        this.readOnlyAllStatus = !UserService.userHasRights(UserType.WORKPLANNER) && UserService.userHasRights(UserType.TRANSPORT_READ_ONLY);

        this.subscriptions.add(this.planningService.getHiringNamesWithEntityId().subscribe(hiring => {
            const entityIds = [...new Set(hiring.data.map(h => h.entity_id) || [])];
            entityIds.forEach(entityId => {
                this.hiringAutocomplete.set(entityId, hiring.data.filter(h => h.entity_id === entityId).map(h => h.name));
            });
        }));

        this.subscriptions.add(this.userService.getList(false, false).subscribe(users => {
            this.employees = users;
        }));

        this.subscriptions.add(this.breakpointObserver.observe('(max-width: 722px)').subscribe(match => {
            this.mobile = match.matches;
        }));
        this.asphaltService.getAsphaltMills().then(mills => {
            this.mills = mills;
        });
        this.subscriptions.add(this.entitiesService.getTypes().subscribe(data => {
            this.entityTypes = data.data;
        }));
        this.subscriptions.add(this.hiringService.getList().pipe(debounceTime(200)).subscribe(hirings => {
            this.hirings = hirings;
            this.hiringMap.clear();
            this.hirings.forEach(hiring => {
                hiring.entitytypes.forEach(entityType => {
                    const current = this.hiringMap.get(entityType.id) || [];
                    current.push(hiring);
                    this.hiringMap.set(entityType.id, current);
                });
            });

        }));


        this.route.params.subscribe(params => {
            let date = params.date;
            if (!date || date.length === 0) {
                date = new Date();
            }
            this.currentDate = new Date(date);
            Utils.setTime(this.currentDate, 0, 0);
            this.generateFormForDay();
        });

        this.subscriptions.add(this.fcPerformerIds.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            this.generateFormForDay();
        }));
    }

    selectedTeamsChange(typeIds) {
        this.teams = typeIds;
        LocalStorage.PlanningFilter = typeIds;
        this.generateFormForDay();
    }

    onDrop(event: CdkDragDrop<string[]>, pf: PlanningForm) {
        moveItemInArray(pf.entities, event.previousIndex, event.currentIndex);
        const previousItemOrder = pf.entities[event.currentIndex - 1]?.planning.order ?? 100;
        const nextItemOrder = pf.entities[event.currentIndex + 1]?.planning.order ?? 100;
        pf.entities.forEach((entity, idx) => {
            if (nextItemOrder < 100) {
                if (idx <= event.currentIndex) {
                    entity.planning.order = nextItemOrder - (event.currentIndex - idx) - 1;
                    entity.planning.orderChanged = true;
                }
            } else if ((nextItemOrder > 100 && previousItemOrder === 100) || previousItemOrder > 100 || event.currentIndex >= pf.entities.length / 2) {
                if (idx >= event.currentIndex) {
                    entity.planning.order = previousItemOrder + 1 + idx - event.currentIndex;
                    entity.planning.orderChanged = true;
                }
            } else {
                if (idx <= event.currentIndex) {
                    entity.planning.order = nextItemOrder - (event.currentIndex - idx) - 1;
                    entity.planning.orderChanged = true;
                }
            }
        });
        this.saveSorting.emit();
    }

    generateFormForDay() {
        this.formDaySubscriptions?.unsubscribe();
        this.formDaySubscriptions = new Subscription();
        this.fcPlanningStatus.setValue('besloten', {emitEvent: false});
        const beginDate = new Date(this.currentDate);
        // We substract 2 days because validator will always add some hours to be safe and this will make cache is filled
        beginDate.setDate(beginDate.getDate() - 2);
        const endDate = new Date(this.currentDate);
        // We add 2 days because validator will always add some hours to be safe and this will make cache is filled
        endDate.setDate(endDate.getDate() + 2);
        this.planningForms = null;

        const endOfDay = Utils.setTime(Utils.newDate(this.currentDate), 23, 59);
        const entityTypeMap$ = this.entitiesService.getTypesMap();
        const entitiesMap$ = this.entitiesService.getMap().pipe(tap(map => this.entitiesMap = map));
        const entities$ = this.entitiesService.getList();
        const planning$ = this.planningService.getFilteredList(beginDate, endDate);
        const unavailable$ = this.entityUnavailableService.getFilteredList(this.currentDate, endDate);
        const fixed$ = this.planningFixedService.getLatestItem();
        const projectMangers$ = this.usersService.getFilteredByType(this.currentDate, endOfDay, UserType.PROJECTMANAGER).pipe(tap(projectManagers => this.projectManagers = projectManagers));
        const executors$ = this.usersService.getFilteredByType(this.currentDate, endOfDay, UserType.EXECUTOR).pipe(tap(executors => this.executors = executors));
        const drivers$ = this.usersService.getFilteredList(this.currentDate, endOfDay, false, false).pipe(tap(drivers => this.drivers = drivers));
        const trucks$ = this.entitiesService.getByType(EntityTypeCode.Truck).pipe(tap(trucks => this.trucks = trucks));
        const lowLoaders$ = this.entitiesService.getByType(EntityTypeCode.LowLoader).pipe(tap(lowLoaders => this.lowLoaders = lowLoaders));
        const planningHass$ = this.planningHasService.getFilteredList(beginDate, endDate);
        const planningStatus$ = this.transportPlanningStatusService.getFilteredList(Utils.setTime(new Date(this.currentDate), 10, 10), Utils.setTime(new Date(this.currentDate), 10, 10));


        this.formDaySubscriptions.add(combineLatest(entitiesMap$, entities$, planning$, entityTypeMap$, unavailable$, fixed$, planningHass$, planningStatus$, projectMangers$, executors$, drivers$, trucks$, lowLoaders$)
            .pipe(debounceTime(this.print ? 1000 : 50))
            .subscribe(([entitiesMap, entities, planningList, typeMap, unavailable, fixed, planningHass, planningStatus]) => {
                if (planningStatus?.length) {
                    this.fcPlanningStatus.setValue(planningStatus[(planningStatus?.length - 1)].status, {emitEvent: false});
                }
                const fixedUntil = new Date(fixed.fixeduntil);
                this.dayIsFixed = fixedUntil >= this.currentDate;
                this.planningHass = planningHass;
                this.planningForms = this.planningForms ?? [];

                if (!this.planningForms.find(pf => pf.planning.is_transport)) {
                    const planningFormTransport = {
                        mainPlanning: {
                            begindate: Utils.setTime(new Date(this.currentDate), 7, 0),
                            enddate: Utils.setTime(new Date(this.currentDate), 15, 30)
                        },
                        planning: {
                            name: 'Transport',
                            is_transport: true,
                            status_id: 3,
                            planning_has: []
                        } as any as AfasProject,
                        entities: [],
                        hasSets: false,
                        asphaltTrucks: 0,
                        wipetruckTrucks: 0
                    } as any as PlanningForm;
                    this.planningForms.push(planningFormTransport);
                }

                if ((this.dayIsFixed && ((UserService.userHasRights(UserType.WORKPLANNER) || UserService.userHasRights(UserType.TRANSPORT_READ_ONLY))) || this.fcPlanningStatus.value !== 'besloten')) {

                    this.planning = planningList;
                    this.EntityTypeMap = typeMap;
                    this.unavailableList = unavailable;
                    this.allEntities = entities as EntityWithAvailable[];
                    const excludedEntityTypeIds = [...typeMap.values()].filter(t => t.is_team && !this.teams.includes(t.id)).map(t => t.id);
                    const visibleEntityTypeIds = this.entityTypes?.filter(p => !!p.visible_transport).map(p => p.id);

                    const curDayPlannings = planningList
                        .filter(p => ![PlanningStatus.geblokkeerd, PlanningStatus.slaapdag].includes(p.status_id))
                        .filter(p => !excludedEntityTypeIds.includes(this.planningService.getMainPlanning(p).entitytype_id))
                        .filter(p => {
                            return (!this.fcPerformerIds.value?.length || this.fcPerformerIds.value?.includes(p.performer) || this.fcPerformerIds.value?.includes(p.asphalt_performer))
                                && p.planning_has.filter(ae => Utils.displayOnDate(ae, p, this.currentDate)
                                    && (!ae.entitytype_id || visibleEntityTypeIds.includes(ae.entitytype_id))
                                ).length > 0;
                        });
                    const handledPlanningForms: number[] = [];
                    curDayPlannings.sort((aa: Planning, bb: Planning) => {
                        const a = this.planningService.getMainPlanning(aa);
                        const b = this.planningService.getMainPlanning(bb);

                        const aNumber = this.EntityTypeMap.get(a.entitytype_id)?.order;
                        const bNumber = this.EntityTypeMap.get(b.entitytype_id)?.order;
                        if (aNumber > bNumber) {
                            return 1;
                        }
                        if (aNumber < bNumber) {
                            return -1;
                        }
                        return 0;
                    }).forEach(planning => {
                        let allEntities = planning.planning_has;
                        allEntities.forEach(entityPlanning => {
                            if (entityPlanning.entity_id) {
                                entityPlanning.entity = entitiesMap.get(entityPlanning.entity_id);
                            }
                            entityPlanning.entitytype = this.EntityTypeMap.get(entityPlanning.entitytype_id);
                            entityPlanning.afas_project_id = planning.afas_project_id;
                            if (!entityPlanning.entity && entityPlanning.entity_id) {
                                console.error(entityPlanning);
                            }
                        });
                        allEntities = allEntities.filter(ae => Utils.displayOnDate(ae, planning, this.currentDate) && ae.entitytype?.visible_transport);

                        const mainPlanning = this.planningService.getMainPlanning(planning);
                        mainPlanning.entity = entitiesMap.get(mainPlanning.entity_id);

                        handledPlanningForms.push(mainPlanning.id);

                        if (!this.planningForms.find(pf => pf.mainPlanning.id === mainPlanning.id || (pf.planning.is_transport && planning.is_transport))) {

                            mainPlanning.begindate = Utils.newDate(mainPlanning.begindate);
                            mainPlanning.enddate = Utils.newDate(mainPlanning.enddate);

                            const asphaltTrucks = Utils.getAsphaltTrucks(planning, this.currentDate);
                            const wipetruckTrucks = Utils.getWipetruckTrucks(allEntities);

                            const planningForm = {
                                mainPlanning,
                                planning,
                                entities: [],
                                hasSets: allEntities.filter(p => p.entitytype_id === EntityTypeCode.Set).length > 0,
                                asphaltTrucks: asphaltTrucks.maxOnAnyTime,
                                wipetruckTrucks
                            };
                            if (planning.status_id === PlanningStatus.definitief) {
                                planningForm.entities.push(...allEntities.map(entity => {
                                    entity.begindate = Utils.newDate(entity.begindate);
                                    entity.enddate = Utils.newDate(entity.enddate);
                                    return {
                                        planning: entity,
                                        fg: this.getEntityFormgroup(entity, planning, planningForm)
                                    };
                                }));

                                this.calculateTrucks(planningForm);
                            }
                            this.planningForms.push(planningForm);
                        } else {
                            this.handleChange(planning);
                        }
                    });
                    // Remove unhandled Plannings, cause those will be empty
                    const unhandledPlanningForms = this.planningForms
                        .filter(pf => handledPlanningForms.indexOf(pf.mainPlanning.id) === -1 && !pf.planning.is_transport);
                    unhandledPlanningForms.forEach(unhandledPlanningForm => {
                        this.planningForms.splice(this.planningForms.indexOf(unhandledPlanningForm), 1);
                    });

                    const allTransportPlanninghas = [].concat(...curDayPlannings.filter(cpd => cpd.is_transport).map(p => p.planning_has)).map(z => z.id);
                    const planningFormTransport = this.planningForms.find(p => p.planning.is_transport);

                    planningFormTransport.entities = planningFormTransport.entities
                        .filter(entity => allTransportPlanninghas.indexOf(entity.planning.id) !== -1);
                    this.sort();

                    this.curDayPlannings = curDayPlannings;

                    this.planningForms.forEach(planningForm => {
                        planningForm.entities.forEach(entity => {
                            // TODO is this too often? Every change triggers a new timemap for every item...
                            this.genStartTimesMap(entity, planningForm);
                            this.genEndTimesMap(entity, planningForm);
                        });
                    });
                }
                if (planningList.length > 0) {
                    setTimeout(() => {
                        document.body.appendChild(document.createElement('readyforpuppeteer'));
                    }, 10000);
                }
            }));
    }

    private calculateTrucks(planningForm: PlanningForm) {
        // Plannings with name > 8 chars = "Auto t.b.v. XXXX", others are manually added "Auto", has no impact on any logic, only on the number displayed
        const existingWipeTrucks = planningForm.entities.filter(e => e.planning.entitytype_id === EntityTypeCode.CutterTruck && e.planning.name?.length > 8).length;
        const existingAsphaltTrucks = planningForm.entities.filter(e => e.planning.entitytype_id === EntityTypeCode.AsfaltTruck && e.planning.name?.length > 8).length;
        planningForm.asphaltTrucks = Utils.getAsphaltTrucks(planningForm.planning, this.currentDate).maxOnAnyTime - existingAsphaltTrucks;
        planningForm.cutterTrucks = Utils.getWipetruckTrucks(planningForm.planning.planning_has, this.currentDate) - existingWipeTrucks;
    }

    private sort() {
        const getSorter = (plnSort: {
            order?: number;
            performer_id?: number;
            driver_user_id: number;
            afas_project_id?: string;
            entity_id: number;
            begindate: Date,
            enddate: Date,
            entitytype?: EntityType,
            name: string
        }, skipPerformer = false, prepend?: string) => {
            return (prepend ?? '') +
                (('' + (plnSort.order ?? 100)).padStart(4, '0')) +
                (skipPerformer ? '000' : ('' + (plnSort.performer_id ?? 0)).padStart(3, '0')) +
                ('' + (plnSort.afas_project_id ?? 0)).padStart(8, '0') +
                ('' + (plnSort.entitytype?.order ?? 0)).padStart(2, '0') +
                ('' + (plnSort.driver_user_id ?? '9999')).padStart(4, '0') +
                ('' + (plnSort.entity_id ?? '9999')).padStart(4, '0') +
                (new Date(plnSort.begindate).getTime() ?? 0) +
                plnSort.name;
        };

        this.planningForms = this.planningForms
            .sort((a, b) => getSorter(a.mainPlanning, true, a.planning.is_transport ? '999' : '000')
                .localeCompare(getSorter(b.mainPlanning, true, b.planning.is_transport ? '999' : '000')));

        this.planningForms.forEach(planningForm => {
            planningForm.entities = planningForm.entities.sort((a, b) => getSorter(a.planning, planningForm.planning.is_transport).localeCompare(getSorter(b.planning, planningForm.planning.is_transport)));
            planningForm.planning.user_planning = planningForm.planning.user_planning?.sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
            planningForm.planning.planning_has = planningForm.planning.planning_has?.sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
        });

        if (!this.initialValuesSaved) {
            this.initialValuesSaved = true;

            const allPlanningHasEntitities: {
                fg: FormGroup,
                planning: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper | PlanningTruck)
            }[] = [];
            this.planningForms.forEach(planningForm => {
                planningForm.entities.forEach(entity => {
                    allPlanningHasEntitities.push(entity);
                });
            });

            const handleNextPHE = () => {
                if (allPlanningHasEntitities.length > 0) {
                    const handlePHE = allPlanningHasEntitities.shift();
                    Utils.triggerValidationP(handlePHE.fg).then(() => {
                        const anyChange = this.writeFormValuesToObject(handlePHE.planning as PlanningHasEntity, handlePHE.fg, handlePHE.fg.value);

                        if (anyChange && handlePHE.planning?.entity_id && handlePHE.planning.begindate && handlePHE.planning.enddate) {
                            this.planningService.saveSingle(handlePHE.planning).then(saveResult => {
                                handlePHE.fg.controls.id.setValue(saveResult.id); // Needed for first save
                                handlePHE.fg.markAsPristine();
                                handleNextPHE();
                            });
                        } else {
                            handleNextPHE();
                        }
                    }, () => {
                        handleNextPHE();
                    }).catch((e => {
                        console.error(e);
                    }));
                }
            };

            handleNextPHE();
        }

    }

    addTrucks(planningForm: PlanningForm) {
        const planning = planningForm.planning;
        const mainPlanning = planningForm.mainPlanning;
        const allEntities = planningForm.planning.planning_has;

        if (allEntities.length > 0) {
            let trucksToAdd = [];
            if (planning.asphalt_list?.length) {
                const asphaltTrucks = Utils.getAsphaltTrucks(planning, this.currentDate);
                asphaltTrucks.timesCountMap.forEach((count, time) => {
                    if (count > 0) {
                        for (let i = 1; i <= count; i++) {
                            const p = new PlanningTruck();
                            p.driver = '';
                            p.name = `Auto t.b.v. asfaltleverantie (${i})`;
                            p.entity = new Entity();
                            p.entitytype = this.entityTypes.find(et => et.id === EntityTypeCode.AsfaltTruck);
                            p.entity.use_once = false;
                            p.entitytype_id = p.entity.entitytype_id = EntityTypeCode.AsfaltTruck;
                            p.entity.name = '';
                            p.planning_id = planning.id;
                            p.transport_origin = this.mills.find(mill => mill.id === planning.asphalt_list[0]?.asphaltmill_id)?.name;
                            p.transport_destination = planning.location;
                            p.begindate = new Date(time);
                            p.enddate = mainPlanning.enddate;
                            trucksToAdd.push(p);
                        }
                    }
                });
            }
            allEntities.filter(e => e['trucks'] > 0 && Utils.getDateOrNull(e.begindate) === this.currentDate.getDate()).forEach(entityPlanning => {
                for (let i = 1; i <= entityPlanning['trucks']; i++) {
                    const p = new PlanningTruck();
                    p.driver = '';
                    p.name = `Auto t.b.v. ${entityPlanning.entity?.name} (${i})`;
                    p.entity = new Entity();
                    p.entitytype = this.entityTypes.find(et => et.id === EntityTypeCode.CutterTruck);
                    p.entity.use_once = false;
                    p.entitytype_id = p.entity.entitytype_id = EntityTypeCode.CutterTruck;
                    p.entity.name = '';
                    p.planning_id = planning.id;
                    p.transport_origin = planning.location;
                    p.transport_destination = entityPlanning['location'];
                    p.begindate = entityPlanning.begindate;
                    p.enddate = entityPlanning.enddate;
                    trucksToAdd.push(p);
                }
            });
            trucksToAdd = trucksToAdd.map((truckToAdd, i) => {
                truckToAdd.begindate = Utils.newDate(truckToAdd.begindate);
                truckToAdd.enddate = Utils.newDate(truckToAdd.enddate);
                return truckToAdd;
            }).filter(truckToAdd => !planningForm.planning.planning_has.find(p => p.name === truckToAdd.name)); // Prevent duplicates, for now by name

            const defaultTrucks = this.trucks.filter(t => t.team_entity_id === mainPlanning.entity_id && !planningForm.planning.planning_has.find(ph => ph.entity_id === t.id));
            trucksToAdd.forEach(truckToAdd => {
                if (defaultTrucks.length > 0) {
                    truckToAdd.entity = defaultTrucks.shift();
                    truckToAdd.entity_id = truckToAdd.entity.id;
                }
            });

            const newEntities = trucksToAdd.map(entity => {
                return {
                    planning: entity,
                    fg: this.getEntityFormgroup(entity, planning, planningForm),
                    highlight: true
                };
            });
            planningForm.entities.push(...newEntities);
            setTimeout(() => {
                newEntities.map(ne => ne.highlight = false);
            }, 1500);

            this.sort();
            this.calculateTrucks(planningForm);
        }
    }

    pdf() {
        if (!this.downloadingPdf) {
            this.downloadingPdf = true;
            const filename = `Transportplanning_${formatDate(this.currentDate, 'dd-MM-yyyy', 'nl')}.pdf`;
            this.subscriptions.add(this.planningService.generateTransportPdf(this.currentDate).subscribe((data: HttpResponse<any>) => {
                saveAs(data, filename);
                this.downloadingPdf = false;
            }, () => {
                this.downloadingPdf = false;
            }));
        }
    }

    addChooseType(planningForm: PlanningForm) {
        this.confirmDialogService.confirm(
            'Kies een soort', '', null, 'Annuleren', this.entityTypes.filter(p => !!p.visible_transport)
        ).then(data => {
            this.addChooseEntity({planningForm, type: data, disableFromCurrentPlanning: true});
        }, () => {

        });
    }

    changeEntity(planning: PlanningHasEntity, planningForm: PlanningForm, pheId: number) {
        this.addChooseEntity({planningForm, type: planning.entitytype, pheId, disableFromCurrentPlanning: false});
    }

    removeEntity(planningForm: PlanningForm, pheId: number) {
        const entity = planningForm.entities.find(e => e.planning.id === pheId);
        if (entity) {
            entity.planning.entity = null;
            entity.fg.controls.entity_id.setValue(null);
            entity.fg.controls.lowloader_entity_id.setValue(null);
            entity.fg.controls.truck_entity_id.setValue(null);
            entity.fg.controls.driver_user_id.setValue(null);
            entity.fg.controls.hiring_id.setValue(null);
            entity.fg.controls.name.setValue(null);
            entity.fg.markAsDirty();
        }
    }

    addChooseEntity(params: { planningForm: PlanningForm, type: EntityType, pheId?: number, disableFromCurrentPlanning: boolean }) {
        const subText = (entity: Entity) => {
            let text = '';
            if (entity.use_once) {
                this.planningHass.forEach(ph => {
                    if (ph.entity_id === entity.id &&
                        (Utils.getDateOrNull(ph.begindate) === Utils.getDateOrNull(this.currentDate) ||
                            Utils.getDateOrNull(ph.enddate) === Utils.getDateOrNull(this.currentDate)
                        )) {
                        const afasId = ph.afas_project_id ?? (this.planning.find(p => p.id === ph.planning_id)?.afas_project_id);
                        text += `${formatDate(ph.begindate, 'HH:mm', 'nl')}-${formatDate(ph.enddate, 'HH:mm', 'nl')} (${ph.planning_id ? '' : 'PP:'}${afasId ?? '-'}) `;
                    }
                });
            }

            text = text.length > 0 ? 'Ingezet: ' + text : '';

            this.unavailableList.filter(u => u.entity_id === entity.id).forEach(ua => {
                if (Utils.getTimeOrNull(ua.begindate) <= now && Utils.getTimeOrNull(ua.enddate) > now) {
                    text = `Onbeschikbaar ${formatDate(ua.begindate, 'dd-MM-yyyy', 'nl')} t/m ${formatDate(ua.enddate, 'dd-MM-yyyy', 'nl')}`;
                }
            });

            return text;
        };

        const now = Utils.getTimeOrNull(this.currentDate);

        const availableFilter = (entity: Entity) => {
            let available = true;
            if (entity.enddate && Utils.getTimeOrNull(entity.enddate) < now) {
                available = false;
            }
            if (entity.begindate && Utils.getTimeOrNull(entity.begindate) > now) {
                available = false;
            }

            return available;
        };

        const disabled = (entity: Entity) => {
            let isDisabled = false;
            this.unavailableList.filter(u => u.entity_id === entity.id).forEach(ua => {
                if (Utils.getTimeOrNull(ua.begindate) <= now && Utils.getTimeOrNull(ua.enddate) > now) {
                    isDisabled = true;
                }
            });
            if (params.disableFromCurrentPlanning && params.planningForm.entities.find(e => e.planning.entity_id === entity.id && e && e.planning.entity.use_once)) {
                isDisabled = true;
            }
            return isDisabled;
        };

        const entities = [...this.allEntities, {name: 'Nader te bepalen', use_once: false, entitytypes: [params.type]} as Entity]
            .filter(p => !!p.entitytypes.find(et => et.id === params.type.id))
            .filter(p => availableFilter(p))
            .map(p => {
                return {
                    entity: p,
                    name: p.name,
                    icon: p.use_once ? '' : 'fa fa-recycle',
                    subtext: subText(p),
                    bgColor: subText(p) ? '' : '#dedce9',
                    disabled: disabled(p)
                };
            })
            .sort((a, b) => {
                const sortString = (z: { name: string; entity: Entity }) => (z.entity?.use_once ? 'AAA' : 'ZZZ') + z.name;
                return sortString(a).localeCompare(sortString(b));
            });
        this.confirmDialogService.confirm(
            'Kies een ' + params.type.name, '', null, 'Annuleren', entities
        ).then(data => {
            if (data) {
                const entity = params.planningForm.entities.find(e => e.planning.id === params.pheId);
                if (entity) {
                    entity.planning.entity = data.entity;
                    entity.fg.controls.entity_id.setValue(data.entity.id);
                    entity.fg.markAsDirty();
                    setTimeout(() => {
                        if (!params.disableFromCurrentPlanning && !entity.fg.controls.entity_id.valid) {
                            entity.planning.entity = null;
                            entity.fg.controls.entity_id.setValue(null);
                            this.confirmDialogService.confirm(
                                'Materieel niet beschikbaar',
                                'Dit materieel is niet beschikbaar voor de gekozen tijden',
                                'Sluiten', null).then(() => {
                            });
                        }
                    });
                } else {
                    this.add(params.planningForm, data.entity, params.type);
                }
            }
        }, () => {

        });
    }

    add(planningForm: PlanningForm, entity: Entity, type: EntityType) {
        const tp = new PlanningHasEntity();
        tp.entity_id = entity.id;
        tp.entity = entity;
        tp.entitytype = type;
        tp.entitytype_id = type.id;
        tp.planning_id = planningForm.planning.id;
        tp.afas_project_id = planningForm.planning.afas_project_id;
        if (planningForm.planning?.location) {
            tp.transport_worksite = planningForm.planning.location;
            tp.transport_worksite_end = planningForm.planning.location;
        }
        if (Utils.dateString(planningForm.mainPlanning.begindate) === Utils.dateString(this.currentDate)) {
            tp.begindate = new Date(planningForm.mainPlanning.begindate);
            tp.enddate = new Date(planningForm.mainPlanning.enddate);
        } else {
            const anyCutter = planningForm.entities.find(e => e.planning.entitytype_id === EntityTypeCode.Cutter
                && Utils.dateString(e.planning.begindate) === Utils.dateString(this.currentDate)
            );
            if (anyCutter) {
                tp.begindate = new Date(anyCutter.planning.begindate);
                tp.enddate = new Date(anyCutter.planning.enddate);
            }
        }

        const pl = new Planning();

        const newEntity = {
            fg: this.getEntityFormgroup(tp, pl, planningForm),
            planning: tp,
            highlight: true
        };
        planningForm.entities.push(newEntity);
        this.genStartTimesMap(newEntity, planningForm);
        this.genEndTimesMap(newEntity, planningForm);

        this.sort();

        // Todo fix offset
        document.getElementsByClassName('highlight')[0]?.scrollIntoView({block: 'start', behavior: 'smooth'});
        setTimeout(() => {
            newEntity.highlight = false;
        }, 3000);

        this.calculateTrucks(planningForm);
    }


    private handleChange(changedPlanning: Planning) {
        const changedPlanningEntities = changedPlanning.planning_has.filter(ae => Utils.displayOnDate(ae, changedPlanning, this.currentDate));
        const planningForm = this.planningForms.find(p => p.planning.id === changedPlanning.id || (p.planning.is_transport && changedPlanning.is_transport));

        if (changedPlanning.status_id === PlanningStatus.definitief) {
            changedPlanningEntities.forEach(changedEntity => {
                // Asfaltteams tonen we niet
                const type = this.entityTypes.find(entityType => entityType.id === changedEntity.entitytype_id);
                if (type.visible_transport) {
                    let entity = planningForm?.entities.find(e => e.planning.id === changedEntity.id);
                    if (!entity) {
                        // Find same entity_id, undefined planning cause this is auto-added item, KEEP juggling-check NULL
                        entity = planningForm?.entities.find(e => e.planning.id == null && e.planning.entity_id === changedEntity.entity_id);
                    }
                    if (entity) {
                        const props = [
                            'comment',
                            'destination',
                            'driver_user_id',
                            'entity_id',
                            'hiring_id',
                            'lowloader_entity_id',
                            'name',
                            'origin',
                            'truck_entity_id'
                        ];
                        if (entity.planning.updated_at !== changedEntity.updated_at) {
                            entity.planning.updated_at = changedEntity.updated_at;
                            props.forEach(prop => {
                                if (changedEntity[prop] && entity.fg.value[prop] !== changedEntity[prop]) {
                                    entity.fg.controls[prop].setValue(changedEntity[prop], {emitEvent: false});
                                }
                            });
                            const fcBeginTime = entity.fg.controls.date['controls'].begintime;
                            const fcEndTime = entity.fg.controls.date['controls'].endtime;
                            const begintime = Utils.getTimeOrNull(changedEntity.begindate);
                            const endtime = Utils.getTimeOrNull(changedEntity.enddate);
                            if (Utils.getTimeOrNull(fcBeginTime.value) !== begintime || Utils.getTimeOrNull(fcEndTime.value) !== endtime) {
                                entity.fg.controls.date['controls'].date.setValue(new Date(changedEntity.begindate), {emitEvent: false});
                                fcBeginTime.setValue(begintime, {emitEvent: false});
                                fcEndTime.setValue(endtime, {emitEvent: false});
                            }
                        }
                    } else {
                        console.log('Adding entity', changedEntity, planningForm, planningForm?.entities.map(e => {
                            return {i: e.planning.id, z: e.planning.name, a: e.planning.entity_id};
                        }));
                        planningForm.entities.push(...[changedEntity].map(ent => {
                            ent.begindate = Utils.newDate(ent.begindate);
                            ent.enddate = Utils.newDate(ent.enddate);
                            ent.entity = this.allEntities.find(p => p.id === ent.entity_id);
                            return {
                                planning: ent,
                                fg: this.getEntityFormgroup(ent, planningForm.planning, planningForm)
                            };
                        }));
                    }
                }
            });
            if (!planningForm.planning.is_transport) {
                // Remove deleted items
                planningForm.entities = planningForm.entities
                    .filter(entity => !entity.planning.id || changedPlanningEntities.map(p => p.id).indexOf(entity.planning.id) !== -1);
            }
        }

        this.calculateTrucks(planningForm);
    }

    prev() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1));
        this.router.navigateByUrl(`transport-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
        this.generateFormForDay();
    }

    next() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
        this.router.navigateByUrl(`transport-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
        this.generateFormForDay();
    }

    setCenterDate() {
        this.currentDate = new Date();
        this.generateFormForDay();
    }

    ngOnDestroy() {
        this.formDaySubscriptions.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    getEntityFormgroup(entity: PlanningHasEntity, planning: Planning, planningForm: PlanningForm) {
        const defaultOrigin = (phe: PlanningHasEntity, pln: Planning) => {
            if (phe.transport_origin) {
                return phe.transport_origin;
            }
            if (this.entitiesMap.get(phe.entity_id)?.default_transport_none) {
                return 'none';
            }
            const driverUser = this.drivers?.find(d => d.id === defaultDriver(phe, pln));
            if (driverUser?.default_stand === DefaultStand.Home || this.entitiesMap.get(phe.entity_id)?.default_transport_home) {
                return this.locationService.formatAddress(driverUser as Address);
            }
            if (phe.entitytype_id === EntityTypeCode.LowLoader) {
                return pln.location;
            }
            return this.locationService.formatAddress(this.locationService.dvdsAddress);
        };
        const defaultWorksite = (phe: PlanningHasEntity, pln: Planning) => {
            if (phe.transport_worksite) {
                return phe.transport_worksite;
            }
            if ([EntityTypeCode.LowLoader,
                EntityTypeCode.Set,
                EntityTypeCode.Cutter,
                EntityTypeCode.Wipetruck,
                EntityTypeCode.StickyWagon
            ].includes(phe.entitytype_id)) {
                return pln.location;
            }
            return null;
        };
        const defaultDestination = (phe: PlanningHasEntity, pln: Planning) => {
            if (phe.transport_destination) {
                return phe.transport_destination;
            }
            if (this.entitiesMap.get(phe.entity_id)?.default_transport_none) {
                return 'none';
            }
            const driverUser = this.drivers?.find(d => d.id === defaultDriver(phe, pln));
            if (driverUser?.default_stand === DefaultStand.Home || this.entitiesMap.get(phe.entity_id)?.default_transport_home) {
                return this.locationService.formatAddress(driverUser as Address);
            }
            if (phe.entitytype_id === EntityTypeCode.Truck) {
                return pln.location;
            }
            return this.locationService.formatAddress(this.locationService.dvdsAddress);
        };

        const defaultLowloader = (phe: PlanningHasEntity, pln: Planning) => {
            const entityDefaultLowloader = phe.entity?.lowloader_entity_id;
            const entityDefaultTrucksLowloader = this.entitiesMap.get(defaultTruck(phe, pln))?.lowloader_entity_id;
            const anyDerivedDefault = phe.entity?.lowloader_entity_id ?? entityDefaultLowloader ?? entityDefaultTrucksLowloader;
            const noneLowloader = this.allEntities.find(e => e.entitytypes?.map(et => et.id).includes(EntityTypeCode.LowLoader) && !e.use_once)?.id;
            return phe.lowloader_entity_id ?? anyDerivedDefault ?? noneLowloader;
        };

        const defaultTruck = (phe: PlanningHasEntity, pln: Planning) => {
            const entityDefaultTruck = phe.entity?.truck_entity_id;
            return phe.truck_entity_id ?? entityDefaultTruck;
        };
        const defaultDriver = (phe: PlanningHasEntity, pln: Planning) => {
            const defaultTruckId = defaultTruck(phe, pln);
            const defaultTrucksDriver = this.entitiesMap.get(defaultTruckId)?.driver_user_id;
            return phe.driver_user_id ?? phe.entity?.driver_user_id ?? defaultTrucksDriver;
        };

        const fcBeginTime = new FormControl(Utils.getTimeOrNull(entity.begindate));
        const fcEndTime = new FormControl(Utils.getTimeOrNull(entity.enddate));
        const fgDate = new FormGroup({
            date: new FormControl(entity.begindate),
            begintime: fcBeginTime,
            endtime: fcEndTime
        });

        const fg = new FormGroup({
            id: new FormControl(entity.id),
            name: new FormControl(entity.name),
            entity_id: new FormControl(entity.entity_id, {asyncValidators: localEntityAvailableValidator(entity, this.planningHasService, this.entitiesMap)}),
            hiring_id: new FormControl(entity.hiring_id),
            planning_id: new FormControl(entity.planning_id),
            lowloader_entity_id: new FormControl(defaultLowloader(entity, planning), {asyncValidators: localEntityAvailableValidator(entity, this.planningHasService, this.entitiesMap)}),
            truck_entity_id: new FormControl(defaultTruck(entity, planning), {asyncValidators: localEntityAvailableValidator(entity, this.planningHasService, this.entitiesMap)}),
            driver_user_id: new FormControl(defaultDriver(entity, planning), {asyncValidators: localUserAvailableValidator(entity, this.userPlanningService, this.planningHasService)}),
            transport_origin: new FormControl(defaultOrigin(entity, planning)),
            transport_worksite: new FormControl(defaultWorksite(entity, planning)),
            transport_worksite_end: new FormControl(entity.transport_worksite_end),
            transport_destination: new FormControl(defaultDestination(entity, planning)),
            comment: new FormControl(entity.comment, {updateOn: 'blur'}),
            afas_project_id: new FormControl(entity.afas_project_id),
            performer_id: new FormControl(entity.performer_id),
            date: fgDate
        });
        if (this.readOnly) {
            fg.disable();
        }

        this.subscriptions.add(fg.valueChanges.pipe(debounceTime(300)).pipe(startWith(fg.value)).subscribe(formValues => {
            const origBegindate = Utils.newDate(entity.begindate);
            const entityChanged = (formValues.entity_id !== entity.entity_id);
            const origWorksite = entity.transport_worksite;
            const origWorksiteEnd = entity.transport_worksite_end;
            const anyChange = this.writeFormValuesToObject(entity, fg, formValues);

            if (entityChanged) {
                this.genStartTimesMap({fg, planning: entity}, planningForm);
            }
            if (entityChanged || origBegindate?.getTime() !== entity.begindate?.getTime()) {
                this.genEndTimesMap({fg, planning: entity}, planningForm);
                fg.controls.entity_id.updateValueAndValidity();
                fg.controls.driver_user_id.updateValueAndValidity();
                fg.controls.lowloader_entity_id.updateValueAndValidity();
            }
            if (fg.value.transport_worksite !== origWorksite && (!entity.transport_worksite_end || (origWorksite === origWorksiteEnd))) {
                entity.transport_worksite_end = fg.value.transport_worksite;
                fg.controls.transport_worksite_end.setValue(fg.value.transport_worksite);
                fg.controls.transport_worksite_end.markAsDirty();
            }
            // Don't save placeholders for type, don't save when time is pending because that might cause removing this item from the screen
            if (anyChange && (!entity.id || fg.dirty) && !!formValues.date.begintime && !!formValues.date.endtime) {
                console.log(anyChange, entity, fg.dirty, entity.id, formValues.date.endtime, entity.enddate);
                planningForm.saving = true;
                this.planningService.saveSingle(entity).then(saveResult => {
                    entity.updated_at = saveResult.updated_at;
                    entity.id = saveResult.id;
                    entity.planning_id = saveResult.planning_id;
                    fg.controls.id.setValue(saveResult.id); // Needed for first save
                    fg.controls.planning_id.setValue(saveResult.planning_id); // Needed for first save of is_transport subsequent
                    setTimeout(() => {
                        planningForm.saving = false;
                        fg.markAsPristine();
                    });
                    this.planningForms.forEach(main => {
                        main.entities?.forEach(pln => {
                            if (fg !== pln.fg && pln.fg.controls.driver_user_id.status == 'INVALID') {
                                if (pln) {
                                    setTimeout(() => {
                                        pln.fg.controls.driver_user_id.updateValueAndValidity();
                                    }, 1000);
                                }
                            }
                        });
                    });
                }, () => {
                    setTimeout(() => {
                        planningForm.saving = false;
                    });
                });
            }
        }));
        this.subscriptions.add(fg.get('driver_user_id').valueChanges.subscribe((value) => {
            const user = this.drivers.find(d => d.id === value);
            if (user && fg.value.driver_user_id !== value) {
                const destinationValue = fg.controls.transport_destination.value;
                const homeAddress = this.locationService.formatAddress(user as Address);
                let worksite = fg.controls.transport_worksite.value ?? destinationValue;
                if (worksite === homeAddress) {
                    worksite = planning.location;
                }

                fg.controls.transport_worksite.setValue(worksite, {emitEvent: false});
                if (user.default_stand === DefaultStand.Home || this.entitiesMap.get(entity.entity_id)?.default_transport_home) {
                    fg.controls.transport_origin.setValue(homeAddress);
                    fg.controls.transport_destination.setValue(homeAddress);
                } else {
                    const dvdsAddress = this.locationService.formatAddress(this.locationService.dvdsAddress);
                    fg.controls.transport_origin.setValue(dvdsAddress, {emitEvent: false});
                    fg.controls.transport_destination.setValue(dvdsAddress, {emitEvent: false});
                    fg.markAsDirty();
                }
            }
        }));

        this.subscriptions.add(fg.get('entity_id').valueChanges.subscribe((value) => {
            const previousValue = fg.value.entity_id;
            const reallyChanged = previousValue !== value;
            const newDriver = this.trucks.find(t => t.id === value)?.driver_user_id;
            if (newDriver && !fg.value.driver_user_id && reallyChanged) {
                fg.controls.driver_user_id.setValue(newDriver);
            }
        }));
        this.subscriptions.add(fg.get('truck_entity_id').valueChanges.subscribe((value) => {
            const previousValue = fg.value.truck_entity_id;
            const reallyChanged = previousValue !== value;
            const newDriver = this.trucks.find(t => t.id === value)?.driver_user_id;
            if (newDriver && !fg.value.driver_user_id && reallyChanged) {
                fg.controls.driver_user_id.setValue(newDriver);
            }

            const newLowloader = this.lowLoaders.find(t => t.truck_entity_id === value)?.id;
            const previousLowloader = this.lowLoaders.find(t => t.truck_entity_id === previousValue)?.id;
            if (newLowloader && (!fg.value.lowloader_entity_id || fg.value.lowloader_entity_id === previousLowloader) && reallyChanged) {
                fg.controls.lowloader_entity_id.setValue(newLowloader);
            }
        }));

        this.genStartTimesMap({fg, planning: entity}, planningForm);
        this.genEndTimesMap({fg, planning: entity}, planningForm);

        return fg;
    }

    private writeFormValuesToObject(entity: PlanningHasEntity, fg: FormGroup, formValues: Partial<{ date: Partial<{ begintime: number; endtime: number; }> }>) {
        let anyChange = false;
        for (const [key, value] of Object.entries(formValues)) {
            if (typeof value === 'object' && value && key === 'date') {
                const origBegin = Utils.getTimeOrNull(entity.begindate);
                const origEnd = Utils.getTimeOrNull(entity.enddate);
                entity.begindate = Utils.newDate(value.begintime);
                entity.enddate = Utils.newDate(value.endtime);

                if (origBegin !== entity.begindate?.getTime() ||
                    origEnd !== entity.enddate?.getTime()) {
                    anyChange = true;
                    console.log('date', entity.id, {
                        a: new Date(origBegin).toTimeString(),
                        b: entity.begindate?.toTimeString(),
                        c: new Date(origEnd).toString(),
                        d: entity.enddate?.toTimeString()
                    });
                }
            } else {
                // Only setting dirty values to prevent the save of default values
                if (fg.controls[key].valid) {
                    // tslint:disable-next-line:triple-equals because null/undefined
                    if (entity[key] != value) {
                        console.log('key', key, entity[key], value);
                        anyChange = true;
                    }
                    entity[key] = value;
                }
            }
        }
        return anyChange;
    }

    openContextMenu(event: MouseEvent, trigger: MatMenuTrigger, triggerElement: HTMLElement) {
        triggerElement.style.left = event.clientX + 5 + 'px';
        triggerElement.style.top = event.clientY + 5 + 'px';
        if (trigger.menuOpen) {
            trigger.closeMenu();
            trigger.openMenu();
        } else {
            trigger.openMenu();
        }
        event.preventDefault();
        event.stopPropagation();
    }

    duplicatePlanning(params: { planning: PlanningHasEntity, main: PlanningForm, formGroup: FormGroup, previous: boolean }) {
        const newEntityPlanning = JSON.parse(JSON.stringify(params.planning));
        delete newEntityPlanning.id;
        delete newEntityPlanning.updated_at;
        if (params.main.planning.is_transport) {
            delete newEntityPlanning.planning_id;
        }
        newEntityPlanning.begindate = Utils.newDate(params.previous ? params.planning.begindate : params.planning.enddate);
        newEntityPlanning.enddate = Utils.newDate(newEntityPlanning.begindate);
        if (params.previous) {
            newEntityPlanning.begindate.setUTCHours(newEntityPlanning.begindate.getUTCHours() - 1);
            newEntityPlanning.transport_destination = 'none';
            params.formGroup.controls['transport_origin'].setValue('none');
        } else {
            newEntityPlanning.enddate.setUTCHours(newEntityPlanning.enddate.getUTCHours() + 1);
            newEntityPlanning.transport_origin = 'none';
            params.formGroup.controls['transport_destination'].setValue('none');
        }
        params.formGroup.markAsDirty();
        const planningForm = this.planningForms.find(pf => pf.planning.id === params.planning.planning_id || (pf.planning.is_transport && params.main.planning.is_transport));

        const fg = this.getEntityFormgroup(newEntityPlanning, newEntityPlanning, planningForm);
        delete newEntityPlanning.entity_id;
        const q = {planning: newEntityPlanning, fg};
        const zIndex = planningForm.entities.findIndex(a => a.planning === params.planning) + 1 ?? 0;
        planningForm.entities.splice(zIndex - (params.previous ? 1 : 0), 0, q);
        fg.controls.entity_id.setValue(params.planning.entity_id);
    }

    deletePlanning(planning: PlanningHasEntity) {
        if (planning.id) {
            this.planningService.deleteSingle(planning).then(() => {
            });
        } else {
            const planningForm = this.planningForms.find(pf => pf.planning.id === planning.planning_id);
            if (planningForm) {
                const index = planningForm.entities?.indexOf(planningForm.entities?.find(p => p.planning === planning));
                if (index && index >= 0) {
                    planningForm.entities?.splice(index, 1);
                }
                this.calculateTrucks(planningForm);
            }
        }
    }

    openEmployees(planning: Planning) {
        this.dialog.open(EmployeesDialogComponent, {
            width: '99vw',
            maxWidth: '1500px',
            minHeight: '500px',
            maxHeight: '95vh',
            disableClose: true,
            data: {
                planning
            }
        });

    }

    openEquipment(planning: Planning) {
        this.dialog.open(EquipmentDialogComponent, {
            width: '99vw',
            maxWidth: '1500px',
            minHeight: '500px',
            maxHeight: '95vh',
            disableClose: true,
            data: {
                planning
            }
        });
    }

    openPlanning(planning: Planning) {
        this.planningDetailDialogService.openPlanning(planning);
    }

    protected genStartTimesMap(planning: {
        fg: FormGroup,
        planning: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck)
    }, planningForm: PlanningForm) {
        const currentDayStart = Utils.setTime(new Date(this.currentDate), 0, 0);
        const mainPlanningStart = new Date(planningForm.mainPlanning.begindate);
        // For now we allow all entities to start 2 hours before the team, this might be filtered to only trucks (with or without asphalt)
        mainPlanningStart.setUTCHours(mainPlanningStart.getUTCHours() - Settings.ASPHALT_SUBSTRACT_HOURS);
        const begin = new Date(Math.min(currentDayStart.getTime(), mainPlanningStart.getTime()));
        const end = new Date(new Date(planningForm.mainPlanning.enddate).getTime());
        if (planningForm.planning.is_transport) {
            Utils.setTime(end, 24, 0);
        }

        if (this.entitiesMap) {
            const timeOptions = Utils.genTimesMapOneEntity(
                begin,
                end,
                planning.planning as PlanningHasEntity,
                planning.planning.id,
                this.planning,
                this.unavailableList,
                false,
                null, // No other items needed cause in this.planning all items are available
                true
            );
            this.startTimesEntity.set(planning.planning, timeOptions);
            if (planning.planning.begindate) {
                const allOptions = ([] as TimeOption[]).concat(...timeOptions.map(p => p.options));
                const option = allOptions.find(b => b.datetime.getTime() === Utils.getTimeOrNull(planning.planning.begindate));

                if (!option || (option && option.notAvailable)) {
                    console.log('RESET STARTTIME', planningForm.planning, allOptions, planning.planning.begindate);
                    planning.planning.begindate = null;
                    planning.planning.enddate = null;
                    planning.fg.get('date').get('begintime').setValue(null);
                    planning.fg.get('date').get('endtime').setValue(null);
                }
            }
        }
    }

    protected genEndTimesMap(planning: {
        fg: FormGroup,
        planning: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck)
    }, planningForm: PlanningForm) {
        if (planning.planning.begindate && planningForm.mainPlanning.enddate) {
            const begin = new Date(planning.planning.begindate);
            begin.setUTCMinutes(begin.getUTCMinutes() + 30);
            begin.setUTCMinutes(begin.getUTCMinutes() - (begin.getUTCMinutes() % 15));
            const end = new Date(begin);
            end.setUTCHours(end.getUTCHours() + Settings.MAX_TIMESPAN_HOURS);
            // This diff prevents endtimes after endtime of mainPlanning
            if (!planningForm.planning.is_transport) {
                const diff = Math.round((end.getTime() - planningForm.mainPlanning.enddate.getTime()) / 36e5) - 1;
                if (diff > 0) {
                    end.setUTCHours((end.getUTCHours() - diff));
                }
            }
            if (this.entitiesMap) {
                const timeOptions = Utils.genTimesMapOneEntity(
                    begin,
                    end,
                    planning.planning as PlanningHasEntity,
                    planningForm.mainPlanning.planning_id,
                    this.planning,
                    this.unavailableList,
                    true,
                    null, // No other items needed cause in this.planning all items are available
                    true
                );
                this.endTimesEntity.set(planning.planning, timeOptions);
                if (planning.planning.enddate) {
                    const planningCutterTime = (new Date(planning.planning.enddate)).getTime();
                    const allOptions = ([] as TimeOption[]).concat(...timeOptions.map(p => p.options));
                    const option = allOptions.find(b => b.datetime.getTime() === planningCutterTime);
                    if (!option || (option && option.notAvailable)) {
                        planning.planning.enddate = null;
                        console.log('RESET ENDDATE', planning.planning.begindate, allOptions, planningCutterTime, new Date(planningCutterTime));
                        planning.fg.get('date').get('endtime').setValue(null);
                    }
                }
            }
        }
    }

    protected readonly LocalStorage = LocalStorage;
}

interface WeekOption {
    startDate: Date;
    endDate: Date;
}
