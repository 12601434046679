import {AsphaltmillAsphalt} from './asphaltmillasphalt.class';

export class ProjectUsedAsphalt {

    id?: number;
    asphaltmill_asphalt_id?: number;
    afas_project_id: string;
    date: Date;
    asphaltmill_id?: number;
    asphaltname: string;
    asphaltcode?: string;
    tons?: number;
    thickness?: number;
    surface: number;
    liter?: number;
    temperature?: number;
    comment?: string;
    application: string;
    created_at?: Date;
    updated_at?: Date;
    time?: Date;
    trucks?: number;
    planning_id?: number;
    asphaltmill_asphalt?: AsphaltmillAsphalt;

}
