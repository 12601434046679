import {Injectable} from '@angular/core';
import {RealtimeService} from './realtime/realtime.service';
import {ApiService} from './api/api.service';
import {WebsocketService} from './websocket/websocket.service';
import {RealtimeType} from './realtime/realtime-type.enum';
import {VkmVehicle} from '../classes/vkm-vehicle';
import {Utils} from '../utils.class';

@Injectable({
    providedIn: 'root'
})
export class VkmVehicleService extends RealtimeService<VkmVehicle> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.vkmVehicle, 'id');
    }

    itemInDaterange(vehicle: VkmVehicle, fromDate: Date, toDate: Date) {
        return !vehicle.date || (Utils.getTimeOrNull(vehicle.date) <= Utils.getTimeOrNull(toDate)
            && Utils.getTimeOrNull(vehicle.date) >= Utils.getTimeOrNull(fromDate));
    }
}
