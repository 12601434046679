import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntitiesService} from '../services/entities/entities.service';
import {PlanningService} from '../services/planning/planning.service';
import {AsphaltService} from '../services/asphalt/asphalt.service';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {AsphaltmillAsphalt} from '../classes/asphaltmillasphalt.class';
import {Asphaltmill} from '../classes/asphaltmill.class';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {Entity} from '../classes/entity.class';
import {MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import {HttpResponse} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {Subscription} from 'rxjs';
import {PlanningAsphalt} from '../classes/planningasphalt.class';
import {ProjectUsedAsphalt} from '../classes/project-used-asphalt.class';

@Component({
    selector: 'app-asphalt-export-dialog',
    templateUrl: './asphalt-export-dialog.component.html',
    styleUrls: ['./asphalt-export-dialog.component.scss']
})
export class AsphaltExportDialogComponent implements OnInit, OnDestroy {

    planning: Planning[];
    asphalt: Map<number, AsphaltmillAsphalt>;
    mills: Asphaltmill[];
    users: Map<number, User>;
    sets: Map<number, Entity>;
    weeks: WeekOption[] = [];
    today = new Date();
    selectedWeek: WeekOption;
    downloadingPdf = new Map<number, boolean>();
    downloadedPdf = new Map<number, boolean>();
    downloadingCsv = new Map<number, boolean>();
    downloadedCsv = new Map<number, boolean>();
    orders: Map<number, MillOrder> = new Map<number, MillOrder>();
    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<AsphaltExportDialogComponent>,
        private entitiesService: EntitiesService,
        private planningService: PlanningService,
        private asphaltService: AsphaltService,
        private userService: UserService) {
    }

    otherWeek() {
        this.downloadedPdf = new Map<number, boolean>();
        this.downloadingPdf = new Map<number, boolean>();
        this.downloadedCsv = new Map<number, boolean>();
        this.downloadingCsv = new Map<number, boolean>();
        this.selectedWeek = null;
        this.planning = null;
    }

    ngOnInit() {
        this.asphalt = new Map<number, AsphaltmillAsphalt>();
        this.sets = new Map<number, Entity>();
        this.asphaltService.getAsphaltMills(false).then(mills => {
            this.mills = mills;
            mills.forEach(mill => {
                mill.asphalt.forEach(asphalt => {
                    this.asphalt.set(asphalt.id, asphalt);
                });
            });
        });
        let monday = new Date();

        // Calculate the monday
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        let sunday = new Date(monday);
        Utils.setTime(sunday, 23, 59);
        sunday.setDate(monday.getDate() + 6);
        this.weeks.push({
            startDate: new Date(monday),
            endDate: new Date(sunday)
        });
        for (let i = 1; i < 15; i++) {
            monday.setDate(monday.getDate() + 7);
            sunday.setDate(sunday.getDate() + 7);
            this.weeks.push({
                startDate: new Date(monday),
                endDate: new Date(sunday)
            });
        }
        this.subscriptions.add(this.userService.getMap().subscribe(users => {
            this.users = users;
        }));

        this.entitiesService.getMap().subscribe(sets => {
            this.sets = sets;
        });

    }

    chooseMill(week: WeekOption) {
        this.selectedWeek = week;
        this.orders = new Map<number, MillOrder>();
        this.planningService.getFilteredList(week.startDate, week.endDate).subscribe(planning => {
            this.orders.clear();
            this.planning = planning;
            this.mills.forEach(mill => {
                this.planning.forEach(p => {
                    p.asphalt_list.filter(a => a.asphaltmill_id === mill.id)
                        .filter(a => Utils.getTimeOrNull(a.time) >= week.startDate.getTime() && Utils.getTimeOrNull(a.time) <= week.endDate.getTime())
                        .forEach(asphalt => {
                            const current = this.orders.get(mill.id) || {orderCount: 0, tonsOrdered: 0};
                            current.tonsOrdered += asphalt.tons;
                            current.orderCount++;
                            this.orders.set(mill.id, current);
                        });
                });
            });
        });
    }

    generatePdf(mill: Asphaltmill) {
        this.downloadingPdf.set(mill.id, true);
        const filename = 'Bestellijst_'
            + Utils.urlTitle(mill.name)
            + '_' + formatDate(this.selectedWeek.startDate, 'dd-MM-yyyy', 'nl')
            + '_' + formatDate(this.selectedWeek.endDate, 'dd-MM-yyyy', 'nl')
            + '.pdf';
        this.asphaltService.generateList(mill, this.selectedWeek.startDate).subscribe((data: HttpResponse<any>) => {
            saveAs(data, filename);
            this.downloadingPdf.delete(mill.id);
            this.downloadedPdf.set(mill.id, true);
        });
    }

    generateCsv(mill: Asphaltmill) {
        this.downloadingCsv.set(mill.id, true);
        const filename = 'Bestellijst_'
            + Utils.urlTitle(mill.name)
            + '_' + formatDate(this.selectedWeek.startDate, 'dd-MM-yyyy', 'nl')
            + '_' + formatDate(this.selectedWeek.endDate, 'dd-MM-yyyy', 'nl')
            + '.csv';
        const monday = this.selectedWeek.startDate;
        const sunday = this.selectedWeek.endDate;

        let filteredList$: Subscription;
        filteredList$ = this.planningService.getFilteredList(monday, sunday).subscribe(planning => {
            this.planning = planning;
            let asphaltList = [];
            planning.forEach(pln => {
                asphaltList = asphaltList.concat(pln.asphalt_list
                    .filter(a => Utils.getTimeOrNull(a.time) >= monday.getTime() && Utils.getTimeOrNull(a.time) <= sunday.getTime()));
            });
            asphaltList = asphaltList.sort((a, b) => {
                if (a.planning_id === b.planning_id && Utils.getDateOrNull(a.time) === Utils.getDateOrNull(b.time)) {
                    return (new Date(a.time)).getTime() - (new Date(b.time)).getTime();
                }
                const compareString = (a: PlanningAsphalt) => '' + Utils.getTimeOrNull(a.time) + a.planning_id;
                return compareString(a).localeCompare(compareString(b));
            });

            let csvContent = 'Startdatum;Starttijd;Einddatum;Eindtijd;Productcode;Productomschrijving;Hoeveelheid in ton;Naam uitvoerder;Telefoonnummer uitvoerder;Emailadres uitvoerder;Aantal auto\'s;Per serie;Opmerkingen;Projectnummer;Projectnaam;Locatieomschrijving;Eigen code\n';
            asphaltList.forEach((asphaltItem: ProjectUsedAsphalt) => {
                const pln = this.planning.find(p => p.id === asphaltItem.planning_id);
                csvContent += formatDate(asphaltItem.time, 'dd-MM-yyyy', 'nl') + ';';
                csvContent += formatDate(asphaltItem.time, 'HH:mm', 'nl') + ';';
                csvContent += formatDate(asphaltItem.time, 'dd-MM-yyyy', 'nl') + ';';
                csvContent += formatDate(asphaltItem.time, 'HH:mm', 'nl') + ';';
                csvContent += asphaltItem.asphaltcode + ';';
                csvContent += asphaltItem.asphaltname + ';';
                csvContent += asphaltItem.tons + ';';
                const performer = this.users.get(pln.asphalt_performer);
                csvContent += (performer?.name ?? 'Onbekend').replaceAll(';', ':,') + ';';
                csvContent += (performer?.phone ?? 'Onbekend').replaceAll(';', ':,') + ';';
                csvContent += (performer?.email ?? 'Onbekend').replaceAll(';', ':,') + ';';
                csvContent += asphaltItem.trucks + ';';
                csvContent += '0;';
                csvContent += (asphaltItem.comment ?? '').replaceAll(';', ':,') + ';';
                csvContent += pln.afas_project_id + ';';
                csvContent += pln.location?.replaceAll(';', ':,') + ';';
                csvContent += (pln.asphalt_location ?? pln.location).replaceAll(';', ':,') + ';';
                csvContent += asphaltItem.id + '';
                csvContent += '\n';
            });

            saveAs(new Blob([String.fromCharCode(0xFEFF), csvContent], {type: 'text/csv;charset=utf-8'}), filename);
            this.downloadingCsv.delete(mill.id);
            this.downloadedCsv.set(mill.id, true);
            filteredList$?.unsubscribe();
        });
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

interface WeekOption {
    startDate: Date,
    endDate: Date;
}

interface MillOrder {
    tonsOrdered: number;
    orderCount: number;
}
