<div class="schedule-navbar">
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content" (click)="today()">
        <p class="large">{{date | date : 'd MMM yyyy'}}</p>
        <p class="small">{{date | date : 'EEEE'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()" [disabled]="date >= maxDate">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="plannings">
    <div class="user" *ngIf="user">
        {{user.name}}
    </div>
    <div class="user" *ngIf="overlapping">
        Je hebt overlappende uren ingevoerd
    </div>
    <div class="user" *ngIf="minutesToday < 0">
        Je hebt meer pauze ingevoerd dan je hebt gewerkt vandaag
    </div>
    <div class="user" *ngIf="minutesToday > 960">
        Je hebt meer dan 16 uur ingevoerd
    </div>
    <div *ngIf="!realisations?.length && !afasAanAfwezigheid?.length" class="planning">
        <div class="container">
            <div class="content">
                <div class="time">
                    niet ingepland
                </div>
                <div class="status mt-1">
                    Wel aan het werk?<br> Voeg zelf registraties toe of neem contact op met de planning.<br><br>
                    Ingelogd als: {{LocalStorage.user?.email}} / {{LocalStorage.user?.id}}
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let aanAfw of afasAanAfwezigheid; let last = last; let index = index;" class="planning">
        <div class="container">
            <div class="content">
                <div class="time">
                    {{aanAfw.hours | minutes}} - {{aanAfw.omschrijving}} {{aanAfw.reasonDescr ? '-' + aanAfw.reasonDescr : ''}}
                </div>
                <div class="status mt-1">
                    {{aanAfw.startDate | date : 'EEEE d MMMM yyyy'}} - {{aanAfw.leaveId ? '#' + aanAfw.leaveId : ''}}
                    ({{aanAfw.leaveCode ?? aanAfw.typeverzuimcode}})
                </div>
                <div class="status mt-3">
                    <i>Deze uren worden nog niet meegeteld in je totaaluren</i>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let realisation of realisations; let last = last; let index = index;"
         [class.travel]="realisation.hourtype !== RealisationHourtype.worktime"
         [class.removed]="realisation.removed"
         [class.disabled]="!(realisation | OwnHourEditAllowed)"
         class="planning">
        <div class="container">
            <div class="content">
                <div class="status"
                     [class.submitted]="realisation.submitted"
                     *ngIf="!realisation.travel">
                    <span>{{realisation.approved ? 'Goedgekeurd' : realisation.submitted ? 'ingediend' : 'concept'}}</span>
                    <span class="type">{{realisation.hourtype | hourtype}}</span>
                    <div class="d-flex justify-content-end">
                        <button *ngIf="!realisation.approved && (realisation | OwnHourEditAllowed)"
                                (click)="editComment(formMap.get(realisation).controls.comment_user)"
                                mat-icon-button
                                color="primary"
                                class="planning-button mt-2">
                            <i class="fas fa-comment"></i>
                        </button>
                        <button *ngIf="realisation.approved && !realisation.comment_user_approved_handled && approvedCommentAllowed"
                                (click)="editComment(formMap.get(realisation).controls.comment_user_approved, 'Opmerking goedgekeurde uren')"
                                mat-icon-button
                                color="primary"
                                class="planning-button override-disabled mt-2">
                            <i class="fas fa-comment"></i>
                        </button>
                    </div>
                </div>
                <div class="text_prepend">{{realisation.text_prepend}}</div>
                <div class="time" (click)="editTime(realisation, 'begindate',-1)"
                     [class.invalid]="!(realisations[index - 1]?.removed) && (realisation.begindate | datetimeBefore : realisations[index - 1]?.enddate) && realisations[index - 1].hourtype !== RealisationHourtype.sleep">
                    {{formMap.get(realisation).value.begindate | date : 'HH:mm'}}<br>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.driving_to && !(formMap.get(realisation).value.begindate | datetimeEquals : realisation.planning_has?.begindate)">
                        {{realisation.planning_has?.begindate | date : 'HH:mm'}}
                    </div>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.worktime && !(formMap.get(realisation).value.begindate | datetimeEquals : realisation.planning_has?.work_begindate)">
                        {{realisation.planning_has?.work_begindate | date : 'HH:mm'}}
                    </div>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.driving_back && !(formMap.get(realisation).value.begindate | datetimeEquals : realisation.planning_has?.work_enddate)">
                        {{realisation.planning_has?.work_enddate | date : 'HH:mm'}}
                    </div>
                    <div class="other-day" *ngIf="(formMap.get(realisation).value.begindate | date : 'YMd') !== (date | date : 'YMd')">
                        {{formMap.get(realisation).value.begindate | date : 'EEEE'}}
                    </div>
                </div>
                <div *ngIf="realisation.hourtype !== RealisationHourtype.travel_to && realisation.hourtype !== RealisationHourtype.travel_back" class="between">
                    <div class="top" *ngIf="realisation.hourtype === RealisationHourtype.worktime">
                        {{realisation.planning?.contractor ?? realisation.user_planning?.project?.name}}
                    </div>
                    <div class="bottom">
                        <ng-container
                            *ngIf="realisation.hourtype === RealisationHourtype.driving_to || realisation.hourtype === RealisationHourtype.driving_back">
                            <i class="fa-solid fa-truck"></i>&nbsp;
                            {{(formMap.get(realisation).value.enddate.getTime() - formMap.get(realisation).value.begindate.getTime()) / 1000 / 60}} min
                            &nbsp;-&nbsp;
                        </ng-container>
                        {{(realisation | realisationAfasProjectIdPipe) ?? realisation.leave?.comment}}
                    </div>
                </div>
                <div *ngIf="realisation.hourtype === RealisationHourtype.travel_to || realisation.hourtype === RealisationHourtype.travel_back"
                     class="between traveltime">
                    <div class="bottom">
                        <div class="chip transparent"><i
                            class="fas fa-car"></i>{{(formMap.get(realisation).value.enddate.getTime() - formMap.get(realisation).value.begindate.getTime()) / 1000 / 60}}
                            min
                        </div>
                    </div>
                </div>
                <div class="time end" (click)="editTime(realisation, 'enddate', 1)">
                    {{formMap.get(realisation).value.enddate | date : 'HH:mm'}}<br>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.driving_to && !(formMap.get(realisation).value.enddate | datetimeEquals : realisation.planning_has?.work_begindate)">
                        {{realisation.planning_has?.work_begindate | date : 'HH:mm'}}
                    </div>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.worktime && !(formMap.get(realisation).value.enddate | datetimeEquals : realisation.planning_has?.work_enddate)">
                        {{realisation.planning_has?.work_enddate | date : 'HH:mm'}}
                    </div>
                    <div class="original"
                         *ngIf="realisation.hourtype === RealisationHourtype.driving_back && !(formMap.get(realisation).value.enddate | datetimeEquals : realisation.planning_has?.enddate)">
                        {{realisation.planning_has?.enddate | date : 'HH:mm'}}
                    </div>
                    <div class="other-day" *ngIf="(formMap.get(realisation).value.enddate | date : 'YMd') !== (date | date : 'YMd')">
                        {{formMap.get(realisation).value.enddate | date : 'EEEE'}}
                    </div>
                </div>
                <div class="text_append">{{realisation.text_append}}</div>
                <div class="comment">
                    <div class="text-center" *ngIf="realisation.approved">
                        Je kunt je uren niet meer aanpassen omdat ze al zijn goedgekeurd.
                    </div>
                    <div class="text-center" *ngIf="!realisation.approved && !(realisation | OwnHourEditAllowed)">
                        Je kunt je uren niet meer aanpassen omdat ze meer dan 48 uur in het verleden zijn.
                    </div>
                    <div class="row">
                        <div class="col-auto pr-0 d-flex align-items-center">
                            <button mat-icon-button
                                    (click)="delete(realisation)"
                                    color="primary"
                                    *ngIf="!realisation.removed"
                                    class="planning-button">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button mat-icon-button
                                    (click)="restore(realisation)"
                                    color="primary"
                                    *ngIf="realisation.removed && !realisation.approved"
                                    class="planning-button">
                                <i class="fas fa-trash-undo"></i>
                            </button>
                        </div>
                        <div class="col">
                            <div *ngIf="realisation.planning_has?.comment && realisation.hourtype === 'worktime'" class="comment">
                                <b>Toelichting planner</b><br>
                                <span>{{realisation.planning_has.comment}}</span>
                            </div>
                            <div *ngIf="realisation.planning_has?.transport_origin && realisation.hourtype === 'worktime'" class="comment">
                                <b>Locaties planner</b><br>
                                <span *ngIf="realisation.planning_has.transport_origin?.length > 0 && realisation.planning_has.transport_origin !== 'none'">
                                    Start: {{realisation.planning_has.transport_origin | replaceIf : 'next' : 'Zie vorige regel'}}<br>
                                </span>
                                <span *ngIf="realisation.planning_has.transport_worksite?.length > 0">
                                    Werk: {{realisation.planning_has.transport_worksite}}<br>
                                </span>
                                <span
                                    *ngIf="realisation.planning_has.transport_destination?.length > 0 && realisation.planning_has.transport_destination !== 'none'">
                                    Einde: {{realisation.planning_has.transport_destination | replaceIf : 'next' : 'Zie volgende regel'}}<br>
                                </span>
                            </div>
                            <div *ngIf="realisation.comment_user" class="comment" (click)="editComment(formMap.get(realisation).controls.comment_user)">
                                <b>Toelichting</b><br>
                                <span [innerHTML]="realisation.comment_user | nl2br"></span>
                            </div>
                            <div *ngIf="realisation.comment_user_approved" class="comment"
                                 (click)="editComment(formMap.get(realisation).controls.comment_user_approved)">
                                <b>Opmerking na goedkeuring</b><br>
                                <span [innerHTML]="realisation.comment_user_approved | nl2br"></span>
                                <ng-container *ngIf="realisation.comment_user_approved_handled">
                                    <br>Verwerkt: {{realisation.comment_user_approved_handled | date: 'd MMMM yyyy HH:mm'}}
                                </ng-container>
                            </div>
                            <div *ngIf="realisation.comment_manager" class="comment">
                                <b>Toelichting uitvoerder</b><br>
                                <span [innerHTML]="realisation.comment_manager | nl2br"></span>
                            </div>
                            <div class="comment"
                                 *ngIf="entityRealisationsMap.get(realisation)?.length > 0 && realisation.hourtype !== 'travel_to' && realisation.hourtype !== 'travel_back'">
                                <ng-container *ngFor="let rea of entityRealisationsMap.get(realisation)">
                                    <ng-container *ngIf="rea.entity?.use_once">
                                        <i class="fa fa-check"></i>
                                        {{rea.entity?.name | licensePlate}}
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section bg-white">
    <div class="container mb-5 pb-5">
        <div class="d-flex justify-content-center">
            <a mat-raised-button color="accent" href="tel:+31639777701">
                <i class="fa-regular fa-phone"></i> Kantoor
            </a>
        </div>
        <div class="insight">
            <div class="hours" *ngIf="realisations?.length">
                <div class="caption">Uren <strong>vandaag</strong></div>
                <div class="time">{{minutesToday | minutes}} uur</div>
                <div *ngIf="!realisations[0]?.user?.overtime" class="chip curpoint" (click)="changePause()" matRipple
                     [class.disabled]="!(realisations[0] | OwnHourEditAllowed) || approved">
                    <i class="fas fa-mug-saucer"></i>{{formPause.value.pause | minutes}}
                </div>
            </div>
            <div class="divider"></div>
            <div class="hours">
                <div class="caption">Totaaluren <strong>week {{(+(date | date : 'w') - ((date | date : 'c') == '0' ? 1 : 0))}}</strong></div>
                <div class="time">{{minutesThisWeek | minutes}} uur</div>
            </div>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button routerLink="{{backTo}}" mat-raised-button color="accent" class="planning-button">
                Terug
            </button>
            <button mat-raised-button color="accent" class="planning-button d-none d-lg-block" routerLink="/dagcontrole/{{date | date : 'yyyy-MM-dd'}}"
                    *ngIf="UserType.GENERAL_HOUR_CHECK | hasUserGroup">
                Dagcontrole
            </button>
            <div class="settings-menu">
                <app-settings-menu [date]="date"></app-settings-menu>
            </div>
            <button mat-raised-button color="accent" class="planning-button d-none d-sm-block" routerLink="/check-hours/{{date | date : 'yyyy-MM-dd'}}"
                    *ngIf="(UserType.EXECUTOR | hasUserGroup) || (UserType.ASPHALTEXECUTOR | hasUserGroup)">
                Urencontrole
            </button>
            <button mat-raised-button color="primary" class="planning-button" (click)="add()" [disabled]="!addAllowed">
                Toevoegen
            </button>
            <ng-container *ngIf="!approved && realisations?.length">
                <button [disabled]="minutesToday < 0 || overlapping || saving || form?.dirty || formPause?.dirty" mat-raised-button color="primary"
                        class="planning-button"
                        (click)="submitHours()">
                    <span class="d-none d-sm-inline">Urenregistratie</span> {{saving ? 'indienen...' : submitted ? 'ingediend' : 'indienen'}}
                </button>
            </ng-container>
        </div>
    </div>
</div>
