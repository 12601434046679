import {Entity} from './entity.class';
import {Planning} from './planning.class';
import {Hiring} from './hiring';
import {EntityType} from '../services/entities/entity-type.class';
import {User} from './user.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';
import {Project} from './project.class';
import {Workorderline} from './workorderline';
import {VkmMechanic} from './vkm-mechanic';

// This should become the only class in favor of planningAsfaltteam, planningSet etc, [WIP]
export class PlanningHasEntity implements RealtimeInterface {
    entitytype_id: number;
    entity_id: number;
    entitytype?: EntityType;
    planning_id: number;
    driver_user_id: number;
    driver_user: User;
    truck_entity_id: number;
    lowloader_entity_id: number;
    lowloader_entity?: Entity;
    work_begindate?: Date;
    begindate: Date;
    enddate: Date;
    work_enddate?: Date;
    name: string;
    driver?: string;
    comment: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
    wdr?: any;
    id: number;
    count: number;
    hiring_id: number;
    hiring?: Hiring;
    entity: Entity;
    planning: Planning;
    transport_origin: string;
    transport_worksite: string;
    transport_worksite_end?: string;
    transport_destination: string;
    order?: number;
    orderChanged?: boolean;

    origin: string;
    destination: string;
    afas_project_id?: string; // Used for is_transport planning childs
    project?: Project;
    performer_id?: number;
    performer?: User;
    selected?: boolean;

    type?: 'MWO' | 'DWO';

    workorder_lines?: Workorderline[];
    drawing?: string;
    vg_plan?: string;
    mechanic_id?: number;
    mechanics?: VkmMechanic[];
    location?: string;
}
