<div class="dialog-container">
    <h1 class="condensed dialog-title">Voertuig {{data.user?.name}}</h1>
    <div class="dialog-content">
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>Kenteken voertuig</mat-label>
            <input [formControl]="form.controls.vehicle" matInput placeholder="AA-AA-AA" type="text">
            <mat-error [control]="form.controls.vehicle"></mat-error>
        </mat-form-field>
        <mat-checkbox [formControl]="form.controls.common">Standaard</mat-checkbox>
        <mat-form-field appearance="fill" class="w-100" *ngIf="!form.value.common">
            <mat-label>Voor datum</mat-label>
            <input (focus)="pickerStart.open()" [matDatepicker]="pickerStart"
                   [formControl]="form.controls.date"
                   matInput placeholder="Voor datum">
            <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
            <mat-error [control]="form.controls.date">

            </mat-error>
        </mat-form-field>
    </div>
    <div class="d-flex flex-wrap pt-3">
        <button mat-button mat-dialog-close>Sluiten</button>
        <div class="spacer"></div>
        <button [disabled]="saving" mat-raised-button color="primary" (click)="save()">
            Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
</div>
