<div class="controls">
    <div class="header">
        <app-header [print]="print"
                    [mainTitle]="'Planning DvdS'"
                    subTitle="Personeel">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector flex-column d-flex" *ngIf="!print">
            <div class="settings">
                <div class="trigger" [cdkMenuTriggerFor]="filterProject">
                    <div class="icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                    <div class="name">
                        Selecteer projecten
                    </div>
                </div>
            </div>
        </div>
        <div class="performer-selector flex-column d-flex mr-3" *ngIf="!print">
            <div class="settings">
                <div class="trigger" [cdkMenuTriggerFor]="filterPerformer">
                    <div class="icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                    <div class="name">
                        Selecteer uitvoerder
                    </div>
                </div>
            </div>
        </div>
        <div class="print-pdf mr-3" *ngIf="!print && !readonly">
            <button (click)="pdf()" color="primary" mat-icon-button>
                <i class="fa-light fa-file-pdf" *ngIf="!downloadingPdf"></i>
                <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
            </button>
        </div>
        <div class="night-planning" *ngIf="!print">
            <mat-checkbox [formControl]="fcNightPlanning" color="primary">Nacht</mat-checkbox>
        </div>
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [disableNext]="disableNext"
                            [fromDate]="fromDate"
                            [primaryWeekEnd]="toDate"
                            [primaryWeekStart]="fromDate"
                            [print]="print"
                            [toDate]="toDate">
        </app-date-indicator>
    </div>
    <div *ngIf="!print" class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div *ngIf="!projectDaysList" class="loading">
    Laden...
</div>
<div class="employee-planning-overview" *ngIf="projectDaysList" [class.night]="fcNightPlanning.value" [class.readonly]="readonly">
    <table class="table">
        <thead class="table-head">
        <tr class="projects">
            <td colspan="2" class="table-head-title">
                Project:
            </td>
            <td [colSpan]="leaveDays.length" class="project">

            </td>
            <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                <td [colSpan]="projectDays.days.length"
                    class="project"
                    [matTooltip]="projectDays.project?.name">
                    {{projectDays.project?.afas_project_id ?? projectDays.worknumber}}<br>
                    <small>{{projectDays.project?.name}}</small>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Plaats:
            </td>
            <td [colSpan]="leaveDays.length"></td>
            <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                <td [colSpan]="projectDays.days.length">
                    <div *ngIf="readonly" class="project-text-info">
                        {{projectDays.project.location ?? '-'}}
                    </div>
                    <ng-container *ngIf="!readonly">
                        <input [matAutocomplete]="auto" *ngIf="projectDays.project?.afas_project_id"
                               [formControl]="projectDays.form.controls.location" matInput placeholder="Locatie" type="text">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="location-complete"
                                          (optionSelected)="addressSelected($event, projectDays.form)">
                            <mat-option *ngFor="let option of addresses" [value]="option.formattedAddress">
                                {{option.formattedAddress}}
                            </mat-option>
                        </mat-autocomplete>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Uitvoerder:
            </td>
            <td [colSpan]="leaveDays.length"></td>
            <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                <td [colSpan]="projectDays.days.length">
                    <div *ngIf="readonly" class="project-text-info">
                        {{(executors | byId : projectDays.project.performer_id)?.name ?? '-'}}
                    </div>
                    <mat-select [formControl]="projectDays.form.controls.performer_id" placeholder="Uitvoerder"
                                *ngIf="projectDays.project?.afas_project_id && !readonly">
                        <mat-option *ngFor="let executor of executors" [value]="executor.id">{{executor.name}}</mat-option>
                    </mat-select>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Projectleider:
            </td>
            <td [colSpan]="leaveDays.length"></td>
            <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                <td [colSpan]="projectDays.days.length">
                    <div *ngIf="readonly" class="project-text-info">
                        {{(projectManagers | byId : projectDays.project.projectmanager_id)?.name ?? '-'}}
                    </div>
                    <mat-select [formControl]="projectDays.form.controls.projectmanager_id" placeholder="Projectleider"
                                *ngIf="projectDays.project?.afas_project_id && !readonly">
                        <mat-option *ngFor="let projectManager of projectManagers"
                                    [value]="projectManager.id">
                            {{projectManager.name}}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>
        </tr>
        <tr class="days">
            <th colspan="2" class="table-head-title">
                Personeel
            </th>
            <th *ngFor="let day of leaveDays; let first = first; let last = last"
                class="day day-{{(day | date : 'E')}}"
                [class.highlight]="highlightDay === day"
                matTooltip="{{day | date : 'E d MMM y'}}"
                [class.last]="last"
                [class.first]="first">
                {{(day | date : 'E') | slice : 0 : 1}}
            </th>
            <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                <th *ngFor="let day of projectDays.days; let first = first; let last = last"
                    class="day day-{{(day.day | date : 'E')}}"
                    [class.highlight]="highlightProject === projectDays.project?.afas_project_id && highlightDay === day.day"
                    matTooltip="{{day.day | date : 'E d MMM y'}}"
                    [class.last]="last"
                    [class.first]="first">
                    {{(day.day | date : 'E') | slice : 0 : 1}}
                </th>
            </ng-container>
        </tr>
        </thead>
        <ng-container *ngFor="let team of teams; let index = index; let last = last">
            <tr class="employee-function" *ngIf="index === 0 || team.entityType.plural_name !== teams[index - 1].entityType.plural_name">
                <td [colSpan]="2" class="function-name">
                    {{team.entityType.plural_name}}
                </td>
                <td [colSpan]="(1 + projectDaysList.length) * 7">

                </td>
            </tr>
            <tr class="employee-row">
                <td class="employee-name font-weight-bold" (click)="team.showUsers = !team.showUsers">
                    {{team.entity.name}}
                </td>
                <td class="day day-count" (click)="team.showUsers = !team.showUsers">
                    <i class="fa-light fa-chevron-right" *ngIf="!team.showUsers"></i>
                    <i class="fa-light fa-chevron-down" *ngIf="team.showUsers"></i>
                </td>
                <td *ngFor="let day of leaveDays; let first = first; let last = last"
                    class="day"
                    [class.last]="last"
                    [class.first]="first">

                </td>
                <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                    <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                        (click)="addTeamPlanning($event, day, team)"
                        (mouseover)="mouseOverTeam(day, team.entity)"
                        class="day"
                        [class.last]="last"
                        [class.first]="first">
                        {{projectDayTeamMap.get((day.day | date : 'yyyy-MM-dd') + '-' + team.entity.id + '-' + projectDays.project?.afas_project_id)?.user_planning?.length}}
                    </td>
                </ng-container>
            </tr>
            <ng-container *ngIf="team.showUsers">
                <tr class="employee-row" *ngFor="let user of team.users; let index = index; let last = last">
                    <td class="employee-name" [class.highlight]="highlight === 'u-' + user.id">
                        {{user.lastname}}, {{user.firstname}} {{user.insertion}}
                    </td>
                    <td class="day day-count"
                        [class.warn]="userWeekCount.get(user.id) < 5"
                        matTooltip="Ingepland op {{userWeekCount.get(user.id)}} dagen">
                        {{userWeekCount.get(user.id)}}
                    </td>
                    <td *ngFor="let day of leaveDays; let first = first; let last = last"
                        class="day"
                        [matTooltipShowDelay]="0"
                        matTooltip="{{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.hourtype | hourtype}} - {{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.comment}} {{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.afas_project_id}}"
                        [class.last]="last"
                        [class.first]="first"
                        (click)="addOrRemoveLeave(user, day)"
                        (contextmenu)="contextUser = user; contextDay = day"
                        [cdkContextMenuTriggerFor]="context_menu">
                        <div class="d-flex flex-column">
                            <ng-container *ngIf="leaveDayUserMap.has((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')">
                                <i class="{{hourtypeIconMap.get(leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-').hourtype)}}"></i>
                            </ng-container>
                            <i *ngIf="blockedMap?.has((day | date : 'yyyy-MM-dd') + '-' + user.id)"
                               class="fa-regular {{blockedMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id).icon}}">
                            </i>
                            <i *ngIf="dayUserMap.has((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')"
                               [matTooltipShowDelay]="0"
                               matTooltip="{{dayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')}}"
                               class="fa-thin fa-user"></i>
                        </div>
                    </td>
                    <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                        <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                            class="day"
                            [class.highlight]="highlight === 't-'+team.entity.id+'-'+(day.day | date : 'yyyy-MM-dd')"
                            [class.night]="day.day | employeeIsNight : projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)"
                            (mouseover)="mouseOver(day, user)"
                            (click)="addUserPlanningTeam($event, day, team, user)"
                            [class.empty]="!day.project?.afas_project_id"
                            [matTooltip]="blockedMap?.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id)?.description"
                            [class.last]="last"
                            [class.first]="first">
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'planning'"
                               class="fa-light fa-truck">
                            </i>
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'userPlanning'"
                               class="fa-light fa-users">
                            </i>
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'project'"
                               class="fa-light fa-user-helmet-safety">
                            </i>
                            <i *ngIf="blockedMap?.has((day.day | date : 'yyyy-MM-dd') + '-' + user.id)"
                               class="fa-regular {{blockedMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id).icon}}">
                            </i>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let user of users; let index = index; let last = last">
            <tr class="employee-function"
                *ngIf="!last && (index === 0 || user.function !== users[index - 1].function)">
                <td [colSpan]="2" class="function-name">
                    {{user.function}}
                </td>
                <td [colSpan]="(1 + projectDaysList.length) * 7">

                </td>
            </tr>
            <tr class="employee-row">
                <td class="employee-name" [class.highlight]="highlight === 'u-' + user.id">
                    {{user.lastname}}, {{user.firstname}} {{user.insertion}}
                </td>
                <td class="day day-count"
                    [class.warn]="userWeekCount.get(user.id) < 5"
                    matTooltip="Ingepland op {{userWeekCount.get(user.id)}} dagen">
                    {{userWeekCount.get(user.id)}}
                </td>
                <td *ngFor="let day of leaveDays; let first = first; let last = last"
                    class="day"
                    [class.last]="last"
                    [matTooltipShowDelay]="0"
                    [matTooltipDisabled]="!leaveDayUserMap.has((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')"
                    matTooltip="{{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.hourtype | hourtype}} - {{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.comment}} {{leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')?.afas_project_id}}"
                    [class.first]="first"
                    (click)="addOrRemoveLeave(user, day)"
                    (contextmenu)="contextUser = user; contextDay = day"
                    [cdkContextMenuTriggerFor]="context_menu">
                    <div class="d-flex flex-column">
                        <ng-container *ngIf="leaveDayUserMap.has((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')">
                            <i class="{{hourtypeIconMap.get(leaveDayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-').hourtype)}}"></i>
                        </ng-container>
                        <i *ngIf="blockedMap?.has((day | date : 'yyyy-MM-dd') + '-' + user.id)"
                           class="fa-regular {{blockedMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id).icon}}">
                        </i>
                        <i *ngIf="dayUserMap.has((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')"
                           [matTooltipShowDelay]="0"
                           matTooltip="{{dayUserMap.get((day | date : 'yyyy-MM-dd') + '-' + user.id + '-')}}"
                           class="fa-thin fa-user"></i>
                    </div>
                </td>
                <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                    <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                        class="day"
                        [class.night]="day.day | employeeIsNight : projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)"
                        (mouseover)="mouseOver(day, user)"
                        (click)="addUserPlanningProject($event, day, projectDayUserMap.get(formatDate(day.day, 'yyyy-MM-dd', 'nl') + '-' + user.id + '-' + projectDays.project?.afas_project_id), user)"
                        [class.empty]="!day.project?.afas_project_id"
                        [matTooltip]="blockedMap?.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id)?.description"
                        [class.last]="last"
                        [class.first]="first">
                        <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'planning'"
                           class="fa-light fa-truck">
                        </i>
                        <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'userPlanning'"
                           class="fa-light fa-users">
                        </i>
                        <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'project'"
                           class="fa-light fa-user-helmet-safety">
                        </i>

                    </td>
                </ng-container>
            </tr>
        </ng-container>
        <tr class="employee-function">
            <td [colSpan]="2" class="function-name">
                Klein materieel
            </td>
            <td [colSpan]="(1 + projectDaysList?.length) * 7">

            </td>
        </tr>
        <ng-container *ngFor="let entity of smallMaterial; let index = index; let last = last">
            <tr class="employee-row">
                <td [colSpan]="2" class="employee-name"
                    [class.highlight]="highlight === 'e-' + entity.id">
                    <div [matTooltip]="entity.name" class="text-right">{{entity.name}}</div>
                </td>
                <td *ngFor="let day of leaveDays; let first = first; let last = last"
                    class="day"
                    [class.last]="last"
                    [class.first]="first">

                </td>
                <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                    <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                        class="day"
                        [class.night]="day.day | entityIsNight : projectDayMaterialMap.get((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)"
                        (mouseover)="mouseOverEntity(day,entity)"
                        (click)="addEntityPlanning($event, day, entity)"
                        [class.last]="last"
                        [class.first]="first">
                        <div class="d-flex flex-column"
                             *ngIf="projectDayMaterialMap.has((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)">
                            <i *ngIf="projectDayMaterialMap.get((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)[0]?.planning_id"
                               class="fa-light fa-truck-container-empty">
                            </i>
                            <i *ngIf="projectDayMaterialMap.get((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)[0]?.afas_project_id"
                               class="fa-light fa-truck-plow">
                            </i>
                        </div>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
        <ng-container *ngFor="let hireEntity of hireEntities; let index = index; let last = last">
            <tr class="employee-function">
                <td [colSpan]="2" class="function-name">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <span>{{hireEntity.name}}</span>
                        <i class="fas fa-plus curpoint" *ngIf="!readonly" (click)="startAddHiring(hireEntity)"></i>
                    </div>
                </td>
                <td [colSpan]="(1 + projectDaysList.length) * 7"></td>
            </tr>
            <tr class="add-hiring" *ngIf="addingHiringForEntityId == hireEntity.id">
                <td [colSpan]="2">
                    <input [matAutocomplete]="hiring"
                           [formControl]="fcAddHiring"
                           #addHiringInp
                           matInput
                           (blur)="addHiring(fcAddHiring.value)"
                           placeholder="Naam inhuur"
                           type="text">
                    <mat-autocomplete autoActiveFirstOption
                                      #hiring="matAutocomplete"
                                      class="location-complete"
                                      (optionSelected)="addHiring($event.option.value)">
                        <mat-option *ngFor="let option of hiringAutocompleteOptions" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                </td>
                <td [colSpan]="(projectDaysList.length + 1) * 7">

                </td>
            </tr>
            <tr class="employee-row" *ngFor="let user of entityHiringNames.get(hireEntity.id); let index = index; let last = last">
                <td class="employee-name" [class.highlight]="highlight === 'u-' + user">
                    {{user}}
                </td>
                <td class="day day-count"
                    [class.warn]="(entityWeekUsedDays.get(user + hireEntity.id)?.length ?? 0) > 5"
                    matTooltip="Ingepland op {{entityWeekUsedDays.get(user + hireEntity.id)?.length ?? 0}} dagen">
                    <div class="count">
                        {{entityWeekUsedDays.get(user + hireEntity.id)?.length ?? ''}}
                    </div>
                    <div class="remove">
                        <i class="fas fa-times" (click)="removeHire(hireEntity.id, user)"></i>
                    </div>
                </td>
                <td *ngFor="let day of leaveDays; let first = first; let last = last"
                    class="day"
                    [class.last]="last"
                    [class.first]="first">

                </td>
                <ng-container *ngFor="let projectDays of projectDaysListFiltered">
                    <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                        class="day"
                        [class.night]="day.day | entityIsNight : projectDayMaterialMap.get((day.day | date : 'yyyy-MM-dd') + '-' + hireEntity.id + '-' + projectDays.project?.afas_project_id + '-' + user)"
                        (mouseover)="mouseOverEntity(day,hireEntity, user)"
                        (click)="addEntityPlanning($event, day, hireEntity, user)"
                        [matTooltip]="blockedMap?.get((day.day | date : 'yyyy-MM-dd') + '-' + hireEntity.id)?.description"
                        [class.last]="last"
                        [class.first]="first">
                        <i *ngIf="projectDayMaterialMap.has((day.day | date : 'yyyy-MM-dd') + '-' + hireEntity.id + '-' + projectDays.project?.afas_project_id + '-' + user)"
                           class="fa-light"
                           [class.fa-user-helmet-safety]="hireEntity.id === EntityId.HiringEmployee"
                           [class.fa-truck-plow]="hireEntity.id === EntityId.HiringMaterials">
                        </i>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </table>
</div>
<div class="action-buttons" *ngIf="!print && !readonly">
    <button mat-fab color="accent"
            (click)="addProject()"
            matTooltip="Project toevoegen"
            matTooltipPosition="left"
            [disabled]="saving || rollingBack">
        <i class="fa-light fa-plus fa-2x"></i>
    </button>
    <button mat-fab color="accent"
            matTooltipPosition="left"
            matTooltip="Wijzigingen ongedaan maken"
            [disabled]="rollingBack || saving || (!userPlanningsToSave?.length && !planningHasToSave?.length && !leavesToSave?.length)"
            (click)="undo()">
        <i class="fa-light fa-rotate-left fa-2x" *ngIf="!rollingBack"></i>
        <i class="fas fa-spinner fa-spin fa-2x" *ngIf="rollingBack"></i>
    </button>
    <button mat-fab color="primary"
            matTooltipPosition="left"
            matTooltip="Wijzigingen opslaan"
            [disabled]="saving || (!userPlanningsToSave?.length && !planningHasToSave?.length && !leavesToSave?.length && !formDirty)"
            (click)="save()">
        <i class="fa-light fa-floppy-disk fa-2x" *ngIf="!saving"></i>
        <i class="fas fa-spinner fa-spin fa-2x" *ngIf="saving"></i>
    </button>
</div>
<ng-template #filterProject>
    <div class="menu" cdkMenu>
        <div>
            Selecteer projecten
        </div>
        <mat-selection-list [formControl]="fcProjectIds">
            <mat-list-option checkboxPosition="before" *ngFor="let projectDays of projectDaysList" [value]="projectDays.project.afas_project_id">
                {{projectDays.project.afas_project_id}}
            </mat-list-option>
        </mat-selection-list>
    </div>
</ng-template>

<ng-template #filterPerformer>
    <div class="menu" cdkMenu>
        <div>
            Selecteer uitvoerder
        </div>
        <mat-selection-list [formControl]="fcPerformerIds" class="performer-selector-dropdown">
            <mat-list-option checkboxPosition="before" *ngFor="let executor of filteredExecutors" [value]="executor.id">
                {{executor.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
</ng-template>
<ng-template #context_menu>
    <div class="context-menu" cdkMenu>
        <div class="menu-title">
            {{contextDay | date : 'EEEE'}} - {{contextUser?.name}}
        </div>
        <div class="divider"></div>
        <button cdkMenuItem class="menu-item info"
                (click)="showInfoDialog()">
            <i class="fa-regular fa-memo-circle-info"></i> Details dag
        </button>
        <div class="divider"></div>
        <ng-container *ngIf="contextUser?.afas_employee_id?.substring(0, 1) !== 'Z'">
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.frost"
                    (click)="setHourType(RealisationHourtype.frost)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.frost)}}"></i> Vorst
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.time_for_time"
                    (click)="setHourType(RealisationHourtype.time_for_time)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.time_for_time)}}"></i> Tijd voor tijd
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.paid_leave"
                    (click)="setHourType(RealisationHourtype.paid_leave)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.paid_leave)}}"></i> Doorbetaald verlof
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.short_absence"
                    (click)="setHourType(RealisationHourtype.short_absence)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.short_absence)}}"></i> Kort verzuim
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.education"
                    (click)="setHourType(RealisationHourtype.education)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.education)}}"></i> Scholing
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.bank_holiday"
                    (click)="setHourType(RealisationHourtype.bank_holiday)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.bank_holiday)}}"></i> Feestdag
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.sleep"
                    (click)="setHourType(RealisationHourtype.sleep)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.sleep)}}"></i> Slapen
            </button>
        </ng-container>
        <ng-container *ngIf="contextUser?.afas_employee_id?.substring(0, 1) === 'Z'">
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.zzp_leave"
                    (click)="setHourType(RealisationHourtype.zzp_leave)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.zzp_leave)}}"></i> ZZP N/b
            </button>
        </ng-container>
    </div>
</ng-template>
