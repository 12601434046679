import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Utils} from '../../utils.class';

@Pipe({
    name: 'MechanicHasPlanning'
})
export class MechanicHasPlanningPipe implements PipeTransform {

    transform(planningHass: PlanningHasEntity[], mechanicId: number, trigger?: any): PlanningHasEntity[] {
        return planningHass?.filter(ph =>
            ph.mechanics.map(m => m.mechanic_id).includes(mechanicId) ||
            (ph.mechanics.map(m => m.sec_mechanic_id).includes(mechanicId) && !!mechanicId) ||
            (!mechanicId && ph.mechanics?.length < 1)
        ).sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
    }

}
