<div cdkMenuBar *ngIf="!mobile">
    <button mat-icon-button *ngIf="viewModel.manageMenu" [cdkMenuTriggerFor]="manageMenu" cdkMenuItem>
        <i class="fa-regular fa-gear"></i>
    </button>
    <button mat-icon-button *ngIf="viewModel.exportMenu" [cdkMenuTriggerFor]="exportMenu" cdkMenuItem>
        <i class="fa-regular fa-file-arrow-down"></i>
    </button>
    <button mat-icon-button *ngIf="viewModel.hoursMenu" [cdkMenuTriggerFor]="hoursMenu" cdkMenuItem>
        <i class="fa-regular fa-clock-two-thirty"></i>
    </button>
    <button mat-icon-button *ngIf="viewModel.planningMenu" [cdkMenuTriggerFor]="planningMenu" cdkMenuItem>
        <i class="fa-regular fa-calendar-days"></i>
    </button>
    <button mat-icon-button [cdkMenuTriggerFor]="personMenu" cdkMenuItem>
        <i class="fa-regular fa-user"></i>
    </button>
</div>

<div cdkMenuBar *ngIf="mobile">
    <button mat-icon-button [cdkMenuTriggerFor]="mobileMenu" (cdkMenuOpened)="open=true" (cdkMenuClosed)="open=false" cdkMenuItem>
        <i class="fa-regular fa-bars" *ngIf="!open"></i>
        <i class="fa-regular fa-xmark" *ngIf="open"></i>
    </button>
</div>

<ng-template #manageMenu>
    <div class="menu list" cdkMenu>
        <ng-container *ngTemplateOutlet="manageMenuContent"></ng-container>
    </div>
</ng-template>

<ng-template #exportMenu>
    <div class="menu list" cdkMenu>
        <ng-component *ngTemplateOutlet="exportMenuContent"></ng-component>
    </div>
</ng-template>
<ng-template #hoursMenu>
    <div class="menu list" cdkMenu>
        <ng-component *ngTemplateOutlet="hoursMenuContent"></ng-component>
    </div>
</ng-template>
<ng-template #planningMenu>
    <div class="menu list" cdkMenu>
        <ng-component *ngTemplateOutlet="planningMenuContent"></ng-component>
    </div>
</ng-template>

<ng-template #personMenu>
    <div class="menu list" cdkMenu>
        <ng-container *ngTemplateOutlet="personMenuContent"></ng-container>
    </div>
</ng-template>

<ng-template #mobileMenu>
    <div class="holder">
        <div class="menu mobile-menu list" cdkMenu>
            <app-planning-filter (selectedTeams)="selectedTeams.emit($event)" *ngIf="showPlanningFilter" [mobile]="true"></app-planning-filter>
            <ng-container *ngTemplateOutlet="exportMenuContent"></ng-container>
            <ng-container *ngTemplateOutlet="manageMenuContent"></ng-container>
            <ng-container *ngTemplateOutlet="hoursMenuContent"></ng-container>
            <ng-container *ngTemplateOutlet="planningMenuContent"></ng-container>
            <ng-container *ngTemplateOutlet="personMenuContent"></ng-container>
        </div>
    </div>
</ng-template>


<ng-template #manageMenuContent>
    <div>
        Beheer
    </div>
    <a *ngIf="viewModel.manageUsers" (click)="users()">Gebruikers</a>
    <a *ngIf="viewModel.manageEntities" (click)="entities()">Materieel</a>
    <a *ngIf="viewModel.manageAsphalt" (click)="asphalt()">Asfalt</a>
    <a *ngIf="viewModel.manageHiringCompanies" (click)="hiringCompanies()">Inhuur bedrijven</a>
    <a *ngIf="viewModel.manageToolbox" (click)="toolbox()">Toolboxen beheren</a>
    <a *ngIf="viewModel.manageProjects" routerLink="/projects">Projecten</a>
</ng-template>
<ng-template #hoursMenuContent>
    <div>
        Uren
    </div>
    <a *ngIf="viewModel.checkHours" routerLink="/check-hours/{{date | date : 'yyyy-MM-dd'}}">Urencontrole</a>
    <a *ngIf="viewModel.generalHourCheck" routerLink="/dagcontrole/{{date | date : 'yyyy-MM-dd'}}">Dagcontrole</a>
    <a *ngIf="viewModel.generalHourCheck" routerLink="/weekcontrole/{{date | date : 'yyyy'}}/{{date | date : 'w'}}">Weekcontrole</a>
    <a *ngIf="viewModel.projectWeekReport" routerLink="/project-rapport/{{projectId ?? 'null'}}/{{date | date : 'w'}}/{{date | date : 'yyyy'}}">Weekrapport</a>
    <a *ngIf="viewModel.projectDayReport" routerLink="/project-dagrapport/{{projectId ?? 'null'}}/{{date | date : 'yyyy-MM-dd'}}">Dagrapport</a>
    <div *ngIf="viewModel.settlementsAndAfas">
        Verloning
    </div>
    <a *ngIf="viewModel.settlementsAndAfas" routerLink="/periode/verrekening">Periodeverrekening</a>
    <a *ngIf="viewModel.settlementsAndAfas" routerLink="/periode/boekingsregels">Boekingsregels</a>
    <a *ngIf="viewModel.settlementsAndAfas" routerLink="/boekingsregels-versturen">Alle boekingsregels</a>
    <a *ngIf="viewModel.settlementsAndAfas" routerLink="/settlements">Afas nacalculatie</a>
    <a *ngIf="viewModel.leave" routerLink="/leave">Verlof</a>
</ng-template>
<ng-template #personMenuContent>
    <div>
        Mijn account
    </div>
    <a class="user" routerLink="/profiel">
        <i class="fa-regular fa-user"></i> {{authenticatedUser?.name}}<br>
        <i class="fa-regular fa-envelope"></i> {{authenticatedUser?.email}}<br>
    </a>
    <a *ngIf="viewModel.hourRegistration" routerLink="/hours/{{date | date : 'yyyy-MM-dd'}}">Urenregistratie</a>
    <a *ngIf="viewModel.periodSettlement" routerLink="/periode/verrekening">Periodeverrekening</a>
    <a (click)="logout()">Uitloggen</a>
    <div>
        Overig
    </div>
    <a (click)="showToolbox()">Toolbox</a>
    <a (click)="showPastToolboxes()">Oude toolboxen</a>
</ng-template>
<ng-template #planningMenuContent>
    <div>
        Planning
    </div>
    <a routerLink="/weekschedule">Persoonlijke planning</a>
    <a routerLink="/">Asfaltplanning</a>
    <a routerLink="/transport-planning">Transportplanning</a>
    <a routerLink="/personeelsplanning">Personeelsplanning</a>
    <a routerLink="/asfaltploegen" *ngIf="viewModel.asphaltTeams">Asfaltploegen</a>
    <ng-container *ngIf="viewModel.vkm">
        <a routerLink="/vkm-planning">VKM planning</a>
        <a routerLink="/vkm-werkorders">VKM werkorders</a>
    </ng-container>
</ng-template>
<ng-template #exportMenuContent>
    <div>
        Exporteren
    </div>
    <a *ngIf="viewModel.manageAsphalt" (click)="exportAsphalt()">Bestellijst asfalt</a>
    <a *ngIf="viewModel.hirePeople" (click)="hirePeople()">Inhuur mensen</a>
    <a *ngIf="viewModel.reportHiringCompanies" (click)="hiredCompanies()">Ingehuurd materieel</a>
    <a *ngIf="viewModel.projectmanagerReport" (click)="projectmanagerReport()">Projectleider rapport</a>
</ng-template>
